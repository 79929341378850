import React, {useState, useEffect} from "react";
import {useRecoilValue} from "recoil";
import {Button, Descriptions, Divider, message, Popconfirm, Skeleton, Space, Spin, Table, Typography} from "antd";
import {FilePdfOutlined, LikeOutlined} from "@ant-design/icons";
import {BACKEND_URL, BoUserIdAtom, BoUserTypeAtom} from "../../Atoms";
import B2bLayout from "./B2bLayout";
import {useNavigate} from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import {addComma, downloadFile, isEmptyString} from "../../common/Util";
import {FlexCenter, FlexColumn} from "../../common/B2bComponent";

const B2bSamchullyConsign = () => {
    const navigate = useNavigate();
    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();

    //로그인 후, username=boUserId=email 일것으로 가정하고 개발.
    const boUserId = useRecoilValue(BoUserIdAtom);
    const boUserType  = useRecoilValue(BoUserTypeAtom);  //anonymous  google kakao naver  b2b추가됨.

    const [list, setList] = useState();

    useEffect(() => {
        //1. 로그인 필요.
        if (isEmptyString(boUserId) || boUserType !== 'b2b') { //email로그인 체크 강화 필요. boUserType을 이용하던지.
            // 로그인이 안되어 있으면. login화면으로 이동.
            window.location.href = '/b2bLoginLanding';
        } else {
            getConsignments();
        }
    }, [boUserId])

    const getConsignments = async () => {
        const data = await axiosGet('/api/b2b/consign');
        console.log("data =", data);
        setList(data);
    };

    const signConsignment = async (date, type) => {
        const result = await axiosPut(`/api/b2b/consign/${date}/${type}`)

        setList(null);
        setTimeout(() => {
            getConsignments();
        }, 3000);
    };

    const ConsignDoc = ({
                            type,
                            title,
                            datedCompany,
                            pdfUrlUnsigned,
                            pdfUrlSigned,
                            signedAt
                        }) => {
        return (
            <Descriptions title={title} bordered={false} column={1}>
                {!signedAt && (
                    <>
                        <Descriptions.Item label="미날인본">
                            {isEmptyString(pdfUrlUnsigned) && (
                                <Spin size="small">생성중</Spin>
                            )}
                            {!isEmptyString(pdfUrlUnsigned)&& (
                                <Typography.Text type="success">
                                    <Button size="small" color="primary" variant="filled"
                                            icon={<FilePdfOutlined />}
                                            onClick={() => {
                                                downloadFile(
                                                    pdfUrlUnsigned,
                                                    `삼천리_채권도시_${title}_${datedCompany.date}_미날인.pdf`
                                                )
                                                    .catch((downloadError) => {
                                                        messageApi.open({
                                                            type: "error",
                                                            content: "PDF 다운로드에 실패했습니다" + (downloadError.message ? ": " + downloadError.message : "")
                                                        });
                                                    })
                                            }}
                                    >다운로드</Button>
                                </Typography.Text>
                            )}
                        </Descriptions.Item>
                        {!isEmptyString(pdfUrlUnsigned) && (
                            <Descriptions.Item label="날인본">
                                <Typography.Text>
                                    <Popconfirm title="확인해주세요"
                                                description={`${title} 날인을 승인하시겠습니까?`}
                                                onConfirm={() => {
                                                    signConsignment(datedCompany.date, type)
                                                }}
                                                okText="네" cancelText="아니오"
                                    >
                                        <Button size="small" color="default" variant="solid"
                                                icon={<LikeOutlined />}
                                        >승인필요</Button>
                                    </Popconfirm>
                                </Typography.Text>
                            </Descriptions.Item>
                        )}
                    </>
                )}
                {signedAt && (
                    <>
                        <Descriptions.Item label="날인본">
                            {isEmptyString(pdfUrlSigned) && (
                                <Spin size="small">생성중</Spin>
                            )}
                            {!isEmptyString(pdfUrlSigned) && (
                                <Typography.Text type="success">
                                    <Button size="small" color="primary" variant="filled"
                                            icon={<FilePdfOutlined />}
                                            onClick={() => {
                                                downloadFile(
                                                    pdfUrlSigned,
                                                    `삼천리_채권도시_${title}_${datedCompany.date}_날인.pdf`
                                                )
                                                    .catch((downloadError) => {
                                                        messageApi.open({
                                                            type: "error",
                                                            content: "PDF 다운로드에 실패했습니다" + (downloadError.message ? ": " + downloadError.message : "")
                                                        });
                                                    })
                                            }}
                                    >다운로드</Button>
                                </Typography.Text>
                            )}
                        </Descriptions.Item>
                    </>
                )}
            </Descriptions>
        );
    };

    const columns = [
        {
            title: "위임일",
            align: "center",
            render: (_, {datedCompany}) => {
                return <p>{datedCompany.date}</p>
            }
        },
        {
            title: "사건갯수",
            align: "center",
            width: 200,
            render: (_, {countOfCases}) => {
                return <p>{addComma(countOfCases)}건</p>
            }
        },
        {
            title: "서류 날인 및 다운로드",
            render: (_, {
                            datedCompany,
                            pdfUrlUnsignedFileCollectiveAgreement, pdfUrlCollectiveAgreement, fileCollectiveAgreementSignedAt,
                            pdfUrlUnsignedFilePowersOfAttorney, pdfUrlPowersOfAttorney, filePowersOfAttorneySignedAt,
                            pdfUrlUnsignedFileCaseAgreements, pdfUrlCaseAgreements, fileCaseAgreementsSignedAt
            }) => {
                return (
                    <Space direction="horizontal">
                        <ConsignDoc type="collectiveagreement"
                                    title="개별사건위임약정(피고목록)"
                                    datedCompany={datedCompany}
                                    pdfUrlUnsigned={pdfUrlUnsignedFileCollectiveAgreement}
                                    pdfUrlSigned={pdfUrlCollectiveAgreement}
                                    signedAt={fileCollectiveAgreementSignedAt}
                        />
                        <Divider type="vertical" dashed={true} style={{height: 60}}/>
                        <ConsignDoc type="powersofattorney"
                                    title="소송위임장"
                                    datedCompany={datedCompany}
                                    pdfUrlUnsigned={pdfUrlUnsignedFilePowersOfAttorney}
                                    pdfUrlSigned={pdfUrlPowersOfAttorney}
                                    signedAt={filePowersOfAttorneySignedAt}
                        />
                        <Divider type="vertical" dashed={true} style={{height: 60}}/>
                        <ConsignDoc type="caseagreements"
                                    title="개별사건위임약정"
                                    datedCompany={datedCompany}
                                    pdfUrlUnsigned={pdfUrlUnsignedFileCaseAgreements}
                                    pdfUrlSigned={pdfUrlCaseAgreements}
                                    signedAt={fileCaseAgreementsSignedAt}
                        />
                    </Space>
                );
            }
        }
    ];

    return (
        <B2bLayout title="삼천리 사건위임 관리">
            {contextHolder}
            <FlexCenter>
                {!list && (
                    <FlexColumn style={{width: "100%"}}>
                        <div style={{height: 200, width: "100%"}}>
                            <Skeleton active={true} />
                        </div>
                    </FlexColumn>
                )}
                {list && (
                    <FlexColumn>
                        <Table bordered size="large" pagination={false}
                               columns={columns}
                               dataSource={list}
                               rowKey={r => r.datedCompany?.date}
                        />
                    </FlexColumn>
                )}
            </FlexCenter>
        </B2bLayout>
    );
};

export default B2bSamchullyConsign;