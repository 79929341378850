import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import {Button, message} from "antd";
import {isMobile} from "react-device-detect";
import {EmptyBox, FlexColumn, FlexColumnCenter, Title2, TitleBox} from "../../common/DeskComponent";
import InfoTellingGrpQnA from "./InfoTellingGrpQnA";


/** b2cLegacy(기존사건)용 사실관계정보 답변 입력 */
const InfoTelling = (props) => {

    const {counselId} = useParams();
    console.log('InfoTelling:  counsel:', counselId);

    const {axiosGet, axiosPost} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();

    const [cm, setCm] = useState();
    const [commonYmq, setCommonYmq] = useState();
    const [ymq, setYmq] = useState();

    //답변 임시저장용
    const [grpYmAnswers, setGrpYmAnswers] = useState([]);


    useEffect( () => {
        if (isMobile) {
            messageApi.info('PC 화면에서 입력하시길 추천드립니다.');
        }
        firstSearch();
    },[counselId])

    const firstSearch = async()=> {

        //TODO
        let infoTellingCmDto  = await axiosGet(`/api/ipoUser/infoTelling/${counselId}`);
        if (!infoTellingCmDto) {
            alert("로그인 오류: 새로고침 해서 다시로그인해 주세요");
            return;
        }else if (!infoTellingCmDto.ymq) {
            alert("사실관계정보 질문설정 오류: 채권도시에 문의해 주세요." + counselId);
            return
        }else if (!infoTellingCmDto.cm ){
            alert("사실관계정보 원장 오류: 채권도시에 문의해 주세요."+ counselId);
            return;
        }
        console.log('cmId', infoTellingCmDto?.cm?.cmId);
        console.log('YogeonQuestion', infoTellingCmDto?.ymq);
        setCm(infoTellingCmDto.cm);
        setCommonYmq(infoTellingCmDto.commonYmq);
        setYmq(infoTellingCmDto.ymq);

        //test중.
        if (Array.isArray(infoTellingCmDto.cm.grpYmAnswers)) {
            setGrpYmAnswers(infoTellingCmDto.cm.grpYmAnswers);
        }

    }


    //grpIdx현재 미사용.
    const saveGrpAnswer = async (isAllSave, grpIdx, changedGrpYmAnswers) => {

        if (isAllSave) { //저장하고, setIpoDone까지 해야함.
            if (window.confirm("모든 답변을 저장/종료하고 제출하시겠습니까?")) {
                let res = await axiosPost(`/api/ipoUser/infoTelling/grpYmAnswers/all/${cm?.cmId}`, changedGrpYmAnswers);

                if (res?.code >= 0) {
                    messageApi.info('제출되었습니다.')
                    //myPage refresh
                    window.opener.document.location.href = window.opener.document.URL;
                    window.close();
                } else {
                    alert("저장 오류:" + res?.msg);
                }
            }
        }else { //grpIdx만 저장해도됨. changedGrpYmAnswers 에서 해당 idx만 저장할지, 다할지 판단필요.
            //return await blabla; or return blabla만 해도 될 듯. todo 2

            return axiosPost(`/api/ipoUser/infoTelling/grpYmAnswers/grp/${cm?.cmId}`, changedGrpYmAnswers);
        }
    }

    return(
        <>
            {contextHolder}
            <TitleBox> {cm?.reRequestFlag?"안내문구를 참고해 '재요청내용의 답변'을 입력해 주세요." : "사건의 '사실관계 정보'를 입력해 주세요."} </TitleBox>

            <FlexColumn style={{ margin: isMobile?8:32}}>
                {Array.isArray(ymq?.grpNames) && Array.from({length: ymq.grpNames.length + 1}, (_, idx) => idx).map((grpIdx) =>
                    <>
                        <Title2> {grpIdx+1}. {grpIdx==0? "신상정보 입력": ymq.grpNames[grpIdx-1]} </Title2>
                        <InfoTellingGrpQnA grpIdx={grpIdx} isFinalGrp={grpIdx == ymq.grpNames.length} commonYmq={commonYmq} ymq={ymq}
                                           grpYmAnswers={grpYmAnswers} setGrpYmAnswers={setGrpYmAnswers} saveGrpAnswer={saveGrpAnswer}
                                           reRequestFlag={cm?.reRequestFlag}
                                           />
                        <br/>
                    </>
                )}

                {/*<EmptyBox>*/}
                {/*    <Button style={{marginTop:32, marginBottom:-32, backgroundColor: '#ffd118', color: 'black'}} type={'primary'}*/}
                {/*            onClick={()=>saveGrpAnswer(true)}> 전체저장 / 제출 </Button>*/}
                {/*</EmptyBox>*/}

            </FlexColumn>

        </>
    )
}

export default InfoTelling;