import { useEffect, useState } from 'react';
import { OrangeButton, WhiteTopBar } from "../common/Common";
import { useRecoilValue } from 'recoil';
import { BACKEND_URL } from '../Atoms';
import {BoNetUtil, homepage2aiLogo, setReturnUrl} from '../common/Util';
import { IntroItem } from './MainPage';
import { useLocation, useNavigate } from 'react-router-dom';

// 이건 엄밀하게 말하면 Email Login 페이지임. 
export function SignInPage(props) {

    const [data, setData] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [promotion, setPromotion] = useState(null);

    useEffect(() => {
        console.log("location.pathname=%o, location.state=%o", location.pathname, location.state);
        console.log(`window.history.state = ${window.history.state.idx}`);
        if (!!location.state?.returnUrl) {
            setReturnUrl(location.state?.returnUrl);
        }
    }, []);

    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/api/v1/landings`, { type: 'banner', isActive: true },
            (resp) => {
                const d = resp.data.data[0];
                d.link = "";
                setData(d);
                console.dir(d);
            },
            (err) => {
                console.error(err);
            }
        );
    }, []);

    useEffect(() => {
        // GET https://www.vatdahm.com/api/v1/promotions?type=counsel_before
        BoNetUtil.get(`${BACKEND_URL}/api/v1/promotions`, {type: "counsel_before"},
            (resp) => {
                console.log("promotion=%o", resp.data);
                // isActive로 조회 한다고 돌아야 한다. 
                let selected = null;
                for (const data of resp.data.data) {
                    if (data.isActive === true) {
                        selected = data;
                        break;
                    }
                }
                if (selected) {
                    setPromotion(selected);
                } else {
                    setPromotion(resp.data.data[0]);
                }                    
            },
            (err) => console.error(err)
        );
        
    }, []);

    return (
        <div className="px-2">
            <WhiteTopBar title="&nbsp;"/>
            <div className="">
                <br/>
                <br/>
                {/*<img src={`/favicon.png`} className="mr-2" style={{height: "2.5rem"}}/>*/}
                {/*<img src={`/assets/bondcity.svg`} alt="채권도시C" style={{height: "2.5rem"}}/>*/}
                {(window.location?.host.startsWith('2ai.')) ?
                    <img src={homepage2aiLogo()} alt="채권 2AI" style={{height: "5rem"}}/>
                    :
                    <img src={`/assets/logoC.png`} alt="채권도시C" style={{height: "5rem"}}/>
                }
            </div>
            <br/><br/>
            <br/>
            <div className="flex justify-content-center	">
                <IntroItem item={data} key={0} id={0} count={1} height="18rem"/>
            </div>
            <br/><br/><br/>
            <SnsLogin/>
        </div>
    );
}

function SnsLogin(props) {

    const onKakaoLogin = () => {
        // kakao에서 Authorization Code를 받을 수 있는 URL을 받아온다. (client_id 포함)
        // #22 익명 사용자의 Access Token이 헤더에 존재하는 상태로 /vws/bo/auth/oauth2/naver 호출하면 토큰에서 username을 추출해 state 파라메터를 달아서 리턴하므로 따로 치환하지 않아도 됩니다.
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/auth/oauth2/kakao`, null,
            (resp) => {
                // "https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=4bb21e8acd4ffac485c2e418a034840f&state=none&redirect_uri=http%3A%2F%2Flocalhost%3A3030%2Fcallback_kakao"
                console.dir(resp.data);                
                const redirectUrl = `${window.location.protocol}//${window.location.host}/callback_kakao`;
                console.log(redirectUrl);
                window.location.href = resp.data.redirectUri + encodeURIComponent(redirectUrl);
            },
            (err) => console.error(err)
        );
    }
    const onNaverLogin = () => {
        // naver에서 Authorization Code를 받을 수 있는 URL을 받아온다. (client_id 포함)
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/auth/oauth2/naver`, null,
            (resp) => {
                // "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9KbeugMPkp6Rc7hHduBT&state=none&redirect_uri=http%3A%2F%2Flocalhost%3A3030%2Fcallback_naver"
                console.dir(resp.data);                
                const redirectUrl = `${window.location.protocol}//${window.location.host}/callback_naver`;
                console.log(redirectUrl);
                window.location.href = resp.data.redirectUri + encodeURIComponent(redirectUrl);
            },
            (err) => console.error(err)
        );
    }
    const onGoogleLogin = () => {
        // google에서 Authorization Code를 받을 수 있는 URL을 받아온다. (client_id 포함)
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/auth/oauth2/google`, null,
            (resp) => {
                // "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9KbeugMPkp6Rc7hHduBT&state=none&redirect_uri=http%3A%2F%2Flocalhost%3A3030%2Fcallback_naver"
                console.dir(resp.data);                
                const redirectUrl = `${window.location.protocol}//${window.location.host}/callback_google`;
                console.log(redirectUrl);
                window.location.href = resp.data.redirectUri + encodeURIComponent(redirectUrl);
            },
            (err) => console.error(err)
        );
    }
    const onAppleLogin = () => {
        // apple에서 Authorization Code를 받을 수 있는 URL을 받아온다. (client_id 포함)
        //TODO:
        /*
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/auth/oauth2/apple`, null,
            (resp) => {
                // "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9KbeugMPkp6Rc7hHduBT&state=none&redirect_uri=http%3A%2F%2Flocalhost%3A3030%2Fcallback_naver"
                console.dir(resp.data);                
                const redirectUrl = `${window.location.protocol}//${window.location.host}/callback_apple`;
                console.log(redirectUrl);
                window.location.href = resp.data.redirectUri + encodeURIComponent(redirectUrl);
            },
            (err) => console.error(err)
        );
        */
    }

    const onEmailSignUp = () => {
        //TODO: 
    }

    return (
        <div>
            <div className="flex justify-content-center align-items-center p-3 border-round cursor-pointer mb-2" style={{backgroundColor: "#FEE500"}}
                onClick={onKakaoLogin}>
                <img src="/assets/kakao.svg"/>
                <span>&nbsp;카카오로 시작하기</span>
            </div>
            {/*2502-네이버 앱으로 로그인 기능이 안드로이드에서 제공되어, 이 경우에는 로그인 후 채권도시로 돌아오지 않는 현상 발생*/}
            {/*<div className="flex justify-content-center align-items-center p-3 border-round cursor-pointer mb-2" style={{backgroundColor: "#1DC800"}}*/}
            {/*    onClick={onNaverLogin}>*/}
            {/*    <img src="/assets/naver.svg"/>*/}
            {/*    <span className="text-0">&nbsp;네이버로 시작하기</span>*/}
            {/*</div>*/}
            <div className="flex justify-content-center align-items-center p-3 border-round cursor-pointer mb-2" style={{backgroundColor: "#F0F0F0"}}
                onClick={onGoogleLogin}>
                <img src="/assets/google.svg"/>
                <span>&nbsp;구글로 시작하기</span>
            </div>
            {/*
            <div className="flex justify-content-center align-items-center p-3 border-round cursor-pointer mb-2" style={{backgroundColor: "#111111"}}
                onClick={onAppleLogin}>
                <img src="/assets/apple.svg"/>
                <span className="text-0">&nbsp;애플로 시작하기</span>
            </div>
            <div className="flex justify-content-center align-items-center p-3 border-round cursor-pointer mb-2" style={{backgroundColor: "#F0F0F0"}}
                onClick={onEmailSignUp}>                
                <span>이메일 가입</span>
            </div>
            <div className="mt-3">
                이미 가입했다면? 로그인하기
    </div>*/}
        </div>
    );
}