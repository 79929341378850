import {useEffect, useState, useRef, useContext} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    BACKEND_URL,
    BoUserIdAtom,
    BoUserTypeAtom,
    CounselEnrollAtom,
    CounselProgressAtom,
    UserObjectIdAtom
} from '../Atoms';
import {Popover, Skeleton, Space, Table} from 'antd';
import {Flex, FlexColumn} from "../common/DeskComponent";
import {addComma, BoNetUtil, getDateStr, homepage2aiLogo} from '../common/Util';
import { Sidebar } from 'primereact/sidebar';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube'
import { BurgerMenu } from './BurgerMenu';
import { Badge } from 'primereact/badge';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';  // 이거 안하면 세로로 스크롤됨 
import classnames from 'classnames';
import axios from 'axios';
import { ScrollToTop, OrangeButton, WhiteButton, useDrag } from '../common/Common';
import { Tag } from 'primereact/tag';
import { isEnrolled } from './CaseListPage';
import { Modal } from 'react-responsive-modal';
import { useMediaQuery } from 'react-responsive'
import {AuthContext} from "../common/AuthContext";
import useAxios from "../hooks/useAxios";
import { goToKakaoChannel } from "../common/Util";
import {isMobile} from 'react-device-detect';
import KakaoChannelGuideModal from "../components/KakaoChannelGuideModal";


// 등록채권수와 진행중 채권수의 개수를 따져서 채팅으로 갈지 채권목록으로 갈지 정한다. 
// export function getChatUrl(enrolledCount, progressCount) {
//     console.log(`InBanner Counsel count: enrolled=${enrolledCount}, progress=${progressCount}`);
//     if (enrolledCount === 1 && progressCount === 0) {
//         return "/chatlist";
//     } else {
//         return "/chat";
//     }
// }

export function MainPage(props) {
    // props.pwaPrompt
    const {axiosGet, axiosPost, axiosPut, axiosDelete, axiosGetNoAuth} = useAxios();


    //2311 gary useContext로 변경: recoil직접써도 됨.
    // const boUserId = useRecoilValue(BoUserIdAtom);
    // const boUserType = useRecoilValue(BoUserTypeAtom);
    // const userObjectId = useRecoilValue(UserObjectIdAtom);
    const {boToken, boUserId, userObjectId, boUserType, isAnonymous} = useContext(AuthContext);
    console.log('==MAIN USER_OID:' + userObjectId + ", BoUID:" + boUserId );//+ ',' + boToken?.accessToken);용

    //2311 gary  recoil로 변경.
    const [enrolledCount, setEnrolledCount] = useRecoilState(CounselEnrollAtom);
    const [progressCount, setProgressCount] = useRecoilState(CounselProgressAtom);
    const chatClickUrl = (enrolledCount >= 1)? '/chatlist' : 'chat'; //chatlist=등록채권 목록.

    const [settings, setSettings] = useState(null);
    const [userChats, setUserChats] = useState([]);
    const [counsels, setCounsels] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showPushDialog, setShowPushDialog] = useState(false);
    const [showPushAgainDialog, setShowPushAgainDialog] = useState(false);
    const [showPushResultDialog, setShowPushResultDialog] = useState(false);
    const [promotion, setPromotion] = useState(null);
    //2311 위로 이동. const [isAnonymous, setAnonymous] = useState(true);
    //2311 미사. const [userInfo, setUserInfo] = useState(null);
    const pwaPrompt = useOutletContext();
    const navigate = useNavigate();
    const isFold = useMediaQuery({ query: '(max-width: 300px)' })  // z-fold를 위한 
    //console.log("isFold=%o", isFold);

    const [bannerData, setBannerData] = useState([]);

    const [showKakaoChannelGuide, setShowKakaoChannelGuide] = useState(false);

    useEffect(() => {
        console.log('Main- USE_EFFECT []:' + BACKEND_URL);

        if (window.location.host.startsWith('b.')) {
            // 조건이 참이면 /b2b로 리다이렉션
            window.location.href = '/b2b';
            return;
        }

        const callFirstAccess = async () => {
            await firstAccess(true);
        }
        //2405: await으로 대기하게 변경 + NO_AUTH로 변경.
        callFirstAccess();

    }, []);

    // 상담목록 세기
    useEffect(() => {
        console.log('userObjectId USE_EFFECT:' + userObjectId);

        if (!userObjectId) return;

        //2402 한번더 하게 수정: 화면이 회색에서 멈추던 현상 방어.
        if (!settings) {
            firstAccess();
        }

        //2405-마케팅 없애서 빼도 될듯
        // termsAccess();

        // GET https://vatdahm.com/api/v1/userChats?limit=10000&page=1&type=counsel&userObjectId=6549e5ac942e5b1a8937aff9
        // isActive=true 인것만 가져오게 함.
        getCounselsByUserChats();


    }, [userObjectId]);

    const firstAccess = async (NO_AUTH) => {

        //from HeaderBanner 2401
        let banner = (NO_AUTH)?  await axiosGetNoAuth('/api/v1/landings', { type: 'banner', isActive: true })
                     :
                     await axiosGet('/api/v1/landings', { type: 'banner', isActive: true });
        if(banner) {
            setBannerData(banner.data);
        }

        //1. landings Settting.
        let landingsSettingsDto = (NO_AUTH)?  await axiosGetNoAuth('/api/v1/landings/settings')
                                 :
                                await axiosGet('/api/v1/landings/settings');
        if(landingsSettingsDto) {
            setSettings(landingsSettingsDto.data);
        }

        //2. promotions :  로그인 모달에 쓰일 이미지 읽기
        let promotionDto = (NO_AUTH)?  await axiosGetNoAuth('/api/v1/promotions', {type: "counsel_before"})
                           :
                           await axiosGet('/api/v1/promotions', {type: "counsel_before"});
        let selected = null;

        if (Array.isArray(promotionDto)) {
            for (const data of promotionDto.data) {
                if (data.isActive === true) {
                    selected = data;
                    break;
                }
            }
            setPromotion(selected);
        }

        //3. 마케팅 정보 수신 확인. -> boUserId useEffect로 이동 필요. 없으니 그 후에 발생하는 userObjectId useEffect로 이동.
    }

    const termsAccess = async () => {
        if (boUserId) {

            //2405-noPopup대신 새로고침 버전.
            // let termsMap = await axiosGet(`/vws/bo/user/terms/${boUserId}`);
            // console.log("terms=%o", termsMap);
            // if (!termsMap?.marketing) { // 무조건 거부하면 뜨게 할건가...
            //     //2405-noPopup setShowPushDialog(true);
            //     firstAccess(); //popup대신 새로고침.
            // }

            //무조건 새로고침 버전
            firstAccess(); //popup대신 새로고침.
        }else {
            console.log("ERROR: NO boUserId in termAccess");//이게 발생하면 위치 조정 필요.
        }
    }

    ///////////////////////////////////////

    // anonymous check : 2311 gary AuthConext로 일원화.
    // useEffect(() => {
    //     console.log('BO_USER_ID-  USE_EFFECT:' + boUserId);
    //     BoNetUtil.checkAnonymous(
    //         (resp) => {
    //             BoNetUtil.get(`${BACKEND_URL}/vws/bo/user/info`, { username: boUserId },
    //                 (resp) => {
    //                     setUserInfo(resp.data);
    //                     setAnonymous(!resp.data.userrole || resp.data.userrole === "ROLE_GUEST");
    //                     console.log(`In burger menu1`);
    //                     console.dir(resp.data);
    //                 },
    //                 (err) => console.error(err)
    //             );
    //         },
    //         (err) => {}
    //     );
    //
    //     //TODO 기존 App.js에 있던 dcolSignInUp 호출 필요. (App.js에서 제거하고...)
    // }, [boUserId]);


    const getCounselsByUserChats = async () => {
        if (userObjectId) {
            let userChatsDto = await axiosGet(`/api/v1/userChats`, {limit: 10000, page: 1, type: "counsel", userObjectId: userObjectId, isActive: true});
            if (userChatsDto?.data && userChatsDto.data.length > 0) {
                let counselArr = [];
                for (const userChat of userChatsDto.data) {
                    let counselDto = await axiosGet(`/api/v1/counsels/${userChat.counselObjectId}`);
                    if (counselDto.data.length == 1) {
                        counselArr.push(counselDto.data[0]);
                        console.log(' Counsel:', counselDto.data[0]);
                    }
                }
                setCounsels(counselArr);
            }
        }
    }


    useEffect(() => {
        console.log('userChats counsels USE_EFFECT:' + counsels?.length);
        if (!counsels || counsels.length == 0) return;
        console.log('  counsel[0]', counsels[0]);

        let ec = 0;  // enrolled
        let pc = 0;  // progressing

        for (const item of counsels) {
            const ie = isEnrolled(item.state);
            if (ie === true) {
                ec++;
            } else {
                pc++;
            }
        }
        setEnrolledCount(ec);
        setProgressCount(pc);
        console.log(`Counsel count: enrolled=${enrolledCount}, progress=${progressCount}`);
    }, [userChats, counsels]);





    // 마케팅 승인/거부 
    const updateMarketing = (v) => {
        if (v === true) {
            setShowPushDialog(false);
            setShowPushAgainDialog(false); 
            //2405-noPopup setShowPushResultDialog(true);
            // 실제 업데이트 

        } else {
            setShowPushAgainDialog(false);
            // 실제 업데이트 
        }
        BoNetUtil.put(`${BACKEND_URL}/vws/bo/user/terms/${boUserId}`, {marketing: v, sms: v, email: v},
            (resp) => {
                console.log("terms updated=%o", resp.data);                                
            },
            (err) => {
                console.error(err);                
            }
        );

        firstAccess();
    }

    const cancelLogin = (e) => {
        //const url = getChatUrl(enrolledCount, progressCount);
        //navigate(url);
        navigate(chatClickUrl);
    }

    const doLogin = (e) => {
        // const url = getChatUrl(enrolledCount, progressCount);
        // navigate("/signin", {state: {returnUrl: url}});
        navigate("/signin", {state: {returnUrl: chatClickUrl}});  //2311 comment: returnUrl 동작안하는듯?
    }

    /* 필요없음. 그냥 height: auto로 하면됨
    const marketingHeight = isFold ? "16.5rem" : "14rem";
    const againHeight = isFold ? "16.5rem" : "14rem";
    const confirmHeight = isFold ? "20rem" : "17rem";
    */


    //UI component 미-재사용 MainPage안으로 일부 이동 (추가이동 가능) : 2311 gary
    const GrayLine = (props) => {
        return (
            //<div style={{height: "10px"}} className="surface-100 my-3"></div>
            <div style={{height: "10px", backgroundColor: "#EAEAEA"}} className="my-3"></div>
        );
    }

    const WhiteBannerNew = (props) => {
        // props.showLogin

        //TODO:(JK) Badge의 개수를 가져오는 방법이 있어야 함.
        const gotoChat = (e) => {

            //original chatbot 코드
            alert(window.location?.host)
            if (window.location?.host.startsWith('localhost') ) { //for DEMO
                if (isAnonymous && props.showLogin) {
                    props.showLogin(true);
                } else {
                    console.log('chatUrl:' + chatClickUrl);
                    navigate(chatClickUrl);
                }
            }
            else if (window.location?.host.startsWith('2ai')) {
                alert('시스템 점검 중입니다. ');
                return;
            } else {
                //카톡채널 링크로 연결- 2405
                // navigate('http://pf.kakao.com/_xbDexfG/chat');
                window.location.href = 'http://pf.kakao.com/_xbDexfG/chat';
                return;
            }

        }

        return <div className="flex align-items-center border-round p-3 mx-3 cursor-pointer p-overlay-badge"
                             style={{height: 77, backgroundColor: "#FBB62D"}}
            onClick={
                window.location?.host.startsWith('2ai') ? () => {}
                :
                window.location?.host.startsWith('localhost') ?  gotoChat //for DEMO
                :
                // gotoChat
                isMobile ? goToKakaoChannel : () => setShowKakaoChannelGuide(true)
            }
        >
            {/*<img className="ml-4" src="/assets/kakaotalk_icon_45x42.png"/>*/}

            {/*<span className="text-l ml-3 font-semibold flex-1" style={{backgroundColor: "#FBB62D", color: "#191919"}}>카카오톡 30초 상담</span>*/}
            <span className="text-l ml-3 font-semibold flex-1" style={{backgroundColor: "#FBB62D", color: "#191919"}}>받아야 할 돈, 지금 바로 상담하기</span>


            {/*<div className="mr-4 font-bold" style={{*/}
            {/*    fontSize: 10,*/}
            {/*    color: "#4B85F0"*/}
            {/*}}> {window.location?.host.startsWith('2ai') ? '채권 관리 방법' : '내 돈 받는 방법'}&nbsp;&nbsp;&nbsp;&gt;</div>*/}
            <Badge value="13" className="hidden" severity="danger"/> {/* 임시로 안보이게 함 */}
        </div>;
    }

    if (window.location.host.startsWith('b.')) {
        return <></>
    }

    //2ai 임시차단 - 2503.
    if (window.location.host.startsWith('2ai.')) {
        return <></>
    }

    // if (window.location.host.startsWith('2ai.')) {
    //     return <> <br/> 준비중 입니다.</>
    // }

    return (
        <div className="App">
            <MainHeader pwaPrompt={pwaPrompt} enrolledCount={enrolledCount} progressCount={progressCount}
                        showLogin={promotion ? setShowDialog : null}
                        showKakaoChannelGuide={isMobile ? undefined : () => setShowKakaoChannelGuide(true)}
            />

            {settings && //화면뜨기전에 skeleton 출력.
                <HScrollBanner settings={settings} bannerData={bannerData}/>
            }
            {!settings &&
                <Skeleton.Input style={{width: 430, height: 320}} block isLoading={settings ? true : false}/>
            }

            <br/>

            {settings && //화면뜨기전에 skeleton 출력.
                <WhiteBannerNew showLogin={promotion ? setShowDialog : null}/>
            }
            {!settings &&
                <Skeleton.Input style={{width: 430, height: 100}} block isLoading={settings ? true : false}/>
            }

            <GrayLine/>
            {settings && //todo 2409: 소송진행건 표시 테이블 화면뜨기전에 skeleton 출력.
                <SueTable settings={settings}/>
            }
            {!settings &&
                <Skeleton.Input style={{width: 430, height: 180}}  block isLoading={settings?true:false}/>
            }


            <GrayLine/>
            {settings && settings.find((item) => item.block === 2)?.title && //2409: 동영상캐러샐 title 있을때만 출력.
                <>
                    <HScrollVideo settings={settings}/>
                    <GrayLine/>
                </>
            }
            { false && !settings && //false추가해서 하단 skeleton 미사용: 2409
                <Skeleton.Input style={{width: 430, height: 110}}  block isLoading={settings?true:false}/>
            }

            {settings && //채권 등록을 시작하세요
                <Block3 settings={settings}
                        showKakaoChannelGuide={
                            window.location?.host.startsWith('2ai') ? () => {} //do nothing
                                :
                            isMobile ? undefined : () => setShowKakaoChannelGuide(true)}
                />
            }
            {!settings &&
                <Skeleton.Input style={{width: 430, height: 240}}  block isLoading={settings?true:false}/>
            }

            {/*<GrayLine/>*/}                    

            {/*<Represent settings={settings}/>*/}

            <Footer/>
            <ScrollToTop/>
            {/* 로그인 유도 다이얼로그 */}
            <Modal open={showDialog} center showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false}
                classNames={{modal: "appWidth border-round-lg p-0"}} styles={{modal: {width: "85vw", height:"auto"}}}>
                <div className="flex flex-column h-full">
                    <div className="p-3 flex flex-column flex-grow-1" style={{backgroundColor: promotion?.color, color: promotion?.fontColor}}>
                        <div className="text-xl font-bold">{promotion?.title}</div>
                        <div className="mt-2">{promotion?.subTitle}</div>
                        <div className="text-center flex-grow-1 flex align-items-center justify-content-center">
                            <div className=""><img src={promotion?.imageUrl} style={{width:"15rem", maxHeight:"11rem"}} /></div>
                        </div>
                    </div>
                    <div className="grid mt-2 mx-2">
                        <div className="col-6"><WhiteButton label="다음에 할게요" onClick={cancelLogin} /></div>
                        <div className="col-6"><OrangeButton label="로그인" onClick={doLogin}/></div>
                    </div>
                </div>
            </Modal>
            {/* 마케팅 정보 수신 */}
            <Modal open={showPushDialog} center classNames={{modal: "appWidth border-round-lg pb-0"}} styles={{modal: {width: "80vw", height:"auto"}}}
                showCloseIcon={false} showHeader={false} closeOnOverlayClick={false} onClose={() => setShowPushDialog(false)}>
                <div className="py-2 px-0">
                    <div className="font-bold text-lg text-center">마케팅 정보를 수신하시겠습니까?</div>
                    <br/>
                    <br/>
                    <div>{(window.location?.host.startsWith('2ai'))?'채권 2AI':'채권도시'}의 이벤트/혜택 소식을 휴대전화로 받아 보실 수 있습니다.</div>
                </div>
                <div className="grid mt-2 mx-0">
                    <div className="col-6 px-1"><OrangeButton label="확인" onClick={() => {updateMarketing(true)}}/></div>
                    <div className="col-6 px-1"><WhiteButton label="아니오" onClick={() => {setShowPushDialog(false); setShowPushAgainDialog(true); firstAccess();}} /></div>
                </div>
            </Modal>
            {/* 마케팅 정보 수신 거부 확인 */}
            <Modal open={showPushAgainDialog} center classNames={{modal: "border-round-lg pb-0"}} styles={{modal: {width: "85vw", height:"auto"}}} 
                showCloseIcon={false} showHeader={false} closeOnOverlayClick={false} onClose={() => setShowPushAgainDialog(false)}>
                <div className="py-2 px-0">
                    <div className="font-bold text-lg text-center">잠시만요!</div>
                    <br/>
                    <br/>
                    <div>유용한 정보가 있어도 알려드릴 수가 없어요.</div>                    
                    <div>알림을 받으시겠어요?</div>                    
                </div>
                <div className="grid mt-2 mx">
                    <div className="col-6 px-1"><OrangeButton label="확인" onClick={() => updateMarketing(true)}/></div>
                    <div className="col-6 px-1"><WhiteButton label="아니오" onClick={() => updateMarketing(false)} /></div>
                </div>
            </Modal>
            {/* 마케팅 정보 수신 승인 확인 */}
            <Modal open={showPushResultDialog} center classNames={{modal: "border-round-lg pb-0"}} styles={{modal: {width: "85vw", height: "auto"}}} 
                showCloseIcon={false} showHeader={false} closeOnOverlayClick={false} onClose={() => setShowPushResultDialog(false)}>
                <div className="py-2 px-0">
                    <div className="font-bold text-lg text-center">광고성 정보 수신안내</div>
                    <br/><br/>
                    <div className="grid">
                        <div className="col-3 font-bold">일시</div>
                        <div className="col-9">{getDateStr(new Date())}</div>
                        <div className="col-3  font-bold">전송자</div>
                        <div className="col-9">{(window.location?.host.startsWith('2ai'))?'채권 2AI':'채권도시'}</div>
                        <div className="col-3  font-bold">처리결과</div>
                        <div className="col-9">광고성 정부 수신동의 (앱 푸시)</div>
                    </div>                    
                </div>
                <div className="mt-2 mx-2">
                    <div className=""><OrangeButton label="확인" onClick={() => setShowPushResultDialog(false)}/></div>
                </div>
            </Modal>
            <KakaoChannelGuideModal open={showKakaoChannelGuide} setOpen={setShowKakaoChannelGuide} />
        </div>
    );
}


export function MainHeader(props) {
    // props.pwaPrompt
    // props.enrolledCount
    // props.progressCount
    // props.showLogin
    const [showSideBar, setShowSideBar] = useState(false);
    const navigate = useNavigate();
    const [showInstallApp, setShowInstallApp] = useState(false);
    const isFold = useMediaQuery({ query: '(max-width: 300px)' })  // z-fold를 위한

    useEffect(() => {
        setShowInstallApp(!!props.pwaPrompt);
    }, [props.pwaPrompt]);

    const installApp = () => {
        console.log(props.pwaPrompt);
        if (!props.pwaPrompt) {
            return;
        }
        props.pwaPrompt.prompt();
        props.pwaPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === "accepted") {
                console.log("App is installing");
                setShowInstallApp(false);
            } else {
                console.log("App is denied");
            }
        });
    }

    return (
        <header>
            <div className="flex px-3 py-3 border-bottom-1 align-items-center" style={{borderColor: "#F5F4F3", color: "#191919"}}>
                <div className="p-0 m-0 flex align-items-center" onClick={() => navigate("/")}>
                    {(window.location?.host.startsWith('m.')) ?
                        <img src={`/assets/logoM.png`} alt="채권도시M" style={{height: "3.1rem"}}/>
                        :
                        (window.location?.host.startsWith('2ai.')) ?
                            <img src={`/assets/logo2ai.png`} alt="채권 2AI" style={{height: "3.1rem"}}/>
                            :
                            <img src={`/assets/logoC.png`} alt="채권도시C" style={{height: "3.1rem"}}/>
                    }
                </div>

                <div className="ml-auto flex align-items-center">
                    {/*App설치 코멘트*/}
                    {/*{ (showInstallApp && !window.location?.host.startsWith('2ai'))&& <Tag className="mr-3 cursor-pointer" icon="pi pi-plus" severity="info" value={isFold?"설치":"App설치"} onClick={installApp}></Tag> }*/}

                    <i className="pi pi-bars px-1 text-xl cursor-pointer" style={{color: "#191919"}} onClick={() => setShowSideBar(true)}></i>
                </div>

            </div>
            <Sidebar visible={showSideBar} position="right" onHide={() => setShowSideBar(false)} className="w-20rem no-padding" showCloseIcon={false}>
                <SideContent closeSidebar={() => setShowSideBar(false)} enrolledCount={props.enrolledCount} progressCount={props.progressCount} showLogin={props.showLogin} showSideBar={setShowSideBar}
                             showKakaoChannelGuide={props.showKakaoChannelGuide}
                />
            </Sidebar>
        </header>
    );
}

export function SideContent(props) {
    // props.closeSidebar
    // props.showLogin
    // props.showSideBar

    return (
        <div>
            <div className="flex px-3">
                <div>

                    {(window.location?.host.startsWith('m.')) ?
                        <img src={`/assets/logoM.png`} alt="채권도시M" style={{height: "1.4rem"}}/>
                        :
                        (window.location?.host.startsWith('2ai.')) ?
                            <img src={homepage2aiLogo()} alt="채권 2AI" style={{height: "1.4rem"}}/>
                            :
                            <img src={`/assets/logoC.png`} alt="채권도시C" style={{height: "1.4rem"}}/>
                    }
                </div>
                <div className="ml-auto">
                    <i className="pi pi-times px-1 text-xl cursor-pointer" onClick={props.closeSidebar}></i>
                </div>
            </div>
            <br/>
            <BurgerMenu showLogin={props.showLogin} showSideBar={props.showSideBar}
                        showKakaoChannelGuide={props.showKakaoChannelGuide}
            />
        </div>
    );
}

/*
{
    "page": 1,
    "limit": 10,
    "totalCount": 5,
    "count": 5,
    "data": [
        {
            "_id": "63229596d089105717387895",
            "isActive": true,
            "link": "/entrance/counsel",
            "index": 1,
            "color": "#5653D7",
            "title": "오랜 금전 스트레스를\n몇 분 만으로 한방에 해결!",
            "subTitle": "몇 번의 클릭으로 지금 바로 확인",
            "block": 1,
            "type": "banner",
            "createdAt": "2022-09-15T03:01:42.269Z",
            "modifiedAt": "2023-09-20T05:40:26.779Z",
            "imageUrl": "https://v1-prod-s3-userprofile.s3.ap-northeast-2.amazonaws.com/images/99f46580-5fb3-46fb-b5a1-3a92c905e3dd",
            "fontColor": "#fff"
        },
    ]
}
*/

// Carousel 대체하여 react-horizontal-scrolling-menu 을 사용하여 구현 
function HScrollBanner(props) {
    // props.settings
    const [banners, setBanners] = useState([]);
    // const [data, setData] = useState([]);
    const [data, setData] = useState(props.bannerData);

    const { dragStart, dragStop, dragMove, dragging } = useDrag();
    const [title, setTitle] = useState([]);  // multi-line 일 수 있음 
    //const { scrollVisibility } = useContext(VisibilityContext);

    useEffect(() => {
        if (!props.settings) return;
        for (const item of props.settings) {
            if (item.block === 1) {
                if (item.title && item.title.length > 1) {
                    setTitle(item.title.split("\n"));
                } else {
                    setTitle([]);
                }
            }
        }
    }, [props.settings]);

    //2401- firstAccess로 이동.
    // useEffect(() => {
    //     BoNetUtil.get(`${BACKEND_URL}/api/v1/landings`, { type: 'banner', isActive: true },
    //         (resp) => {
    //             setData(resp.data.data);
    //             //console.dir(resp.data.data);
    //         },
    //         (err) => {
    //             console.error(err);
    //         }
    //     );
    // }, []);


    // dragging이 되기는 되는데... 매우 부자연스럽다. 
    // 그래서 Wheel로 대체한다. 
    const handleDrag = (visibility) => (ev) => 
        dragMove(ev, (posDiff) => {
            //console.log(`${ev}, ${posDiff}, ${visibility.scrollContainer.current}`);
            if (visibility.scrollContainer.current) {
                visibility.scrollContainer.current.scrollLeft += posDiff;
            }
        });

    // onWheel은 또 횡스크롤과 전체 종스크롤이 동시에 일어나네... 
    function onWheel(apiObj, ev) {
        const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
        if (isTouchpad) {
            ev.stopPropagation();
            return;
        }
        //ev.stopPropagation();
        if (ev.deltaY < 0) {
            apiObj.scrollNext();            
        } else if (ev.deltaY > 0) {
            apiObj.scrollPrev();
        }
    }
        
    return (
        <div>
            <div className="text-left mx-3 mb-3 text-xl font-bold">
            {
                title.map((item, index) => <div key={index}>{item}</div>)
            }
            </div>
            <ScrollMenu>
                {
                    data.map((item, id) => {
                        return (
                            <IntroItem item={item} key={id} id={id} count={data.length}/>
                        )
                    })
                    
                }
            </ScrollMenu>
        </div>
    )
}

export function IntroItem(props) {
    // props.item
    // props.id
    // props.count
    // props.height: SignIn Page에서 사용하기 위해 
    const [lines, setLines] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (!props.item?.title) return;
        setLines(props.item.title?.split("\n"));
    }, [props.item])

    const handleClick = (e) => {
        if (!props.item.link) return;
        //TODO: 이건 임시 코드임. old vatdahm과 호환을 위해 링크를 변환한다. 
        let goto = props.item.link;
        if (goto.startsWith("http")) {
            // 절대경로는 새 창으로
            window.open(goto, "_blank");
        } else {
            if (goto === "/entrance/counsel") goto = "/chat";
            else if (goto === "/introduce") goto = "/company";
            else if (goto === "/entrance/nft") goto = "/";
            else if (goto === "/login") goto = "/signin";
            console.log(`Go to ${goto}`);
            navigate(goto);
        }
    }

    // const height = props.height || "18.8rem";
    const height = props.height || "301px";
    console.log('Main Scroll height', height);
    
    // 제일 왼쪽과 오른쪽에는 더 큰 margin을 주기 위해서, 이렇게 복잡한 조건이 걸리게 된 것임 
    if (!props.item) return null;
    return (        
        <div className={classnames("border-round-lg py-3 pl-3 pr-2 flex flex-column", {"ml-3": props.id === 0}, {"ml-2": props.id !== 0}, {"mr-3": props.id === props.count-1}, {"cursor-pointer": !!props.item?.link})} 
            style={{backgroundColor: props.item?.color, width: "16.5rem", height: height}} key={props.id} onClick={handleClick}>            
            <div className="text-left" style={{color: props.item?.fontColor, paddingTop: "0.02rem"}}>
                <div className="mb-0 text-lg font-bold">
                    {lines.map((line, index) => <div key={index}>{line}</div>)}
                </div>
                <div className="text-sm mt-2">
                    <span>{props.item.subTitle}</span>
                </div>
            </div>
            <div className="flex-grow-1 flex align-items-center justify-content-center mt-3">
                <div className="text-center w-100 pl-3">
                    <img src={props.item.imageUrl} className="" style={{width:"95%"}}/>
                </div>
            </div>
            { props.item.link ? 
            <div className="absolute px-2 py-1 border-round" style={{bottom: "1rem", width: "4.5rem", backgroundColor: "rgba(0,0,0,.3)", color: "#f5f4f3"}}>
                Click &gt;
            </div>
            : null }
        </div>
    )
}


// 이미지 통으로 된 영역임 
/// @deprecated
// export function WhiteBanner(props) {
//     // props.image
//
//     return (
//         <div className="">
//             <Link to={props.url}>
//                 <img src={`${BACKEND_URL}${props.image}`} style={{ width: "300px" }} />
//             </Link>
//         </div>
//     );
// }
const SueTable = (props) => {

    const [dataSource, setDataSource] = useState();
    const {axiosGet} = useAxios();


    useEffect(() => {
        firstSearch();

    },[])

    const firstSearch = async ()=> {

        // let ds = await axiosGet('/api/v1/landings/block5');
        //let ds = await axiosGet('/api/landings/block5'); //최초 401에러 피하기 위해 이동.

        // 인증없이 Get하기.
        let {data:ds} = await axios.get(BACKEND_URL + '/api/landings/block5');
        console.log('block5:', ds);

        if (window.location.href.includes('localhost')) {
            ds = [...ds, ...ds, ...ds, ...ds, ...ds, ...ds, ...ds];
        }

        setDataSource(ds);
    }


    const columns = [
        {title: <div style={{textAlign: 'center'}}> 사건번호 </div>,
            key: '사건번호', dataIndex: '사건번호',
            render:(text, record, index) => <div style={{textAlign: 'center'}}> {text} </div>,
            width: 200,},
        {title: <div  style={{textAlign: 'center'}}> 소송금액 </div>,
            key: '소가', dataIndex: '소가',
            render:(text, record, index) => <div style={{textAlign: 'center'}}> {addComma(text)}원 </div>,
            width: 200,},
    ];

    return (
        <>
            <div className="text-left mx-3 mb-3 text-xl font-bold">
                {props.settings[5] && props.settings[5].title &&
                    <>
                        <div> {props.settings[5].title} </div>

                        {dataSource &&
                            <Table style={{marginTop:8}} size="small" columns={columns} dataSource={dataSource} pagination={true}/>
                        }
                    </>
                }



            </div>

        </>
    )
}

// Carousel 대체하여 react-horizontal-scrolling-menu 을 사용하여 구현
function HScrollVideo(props) {

    const [data, setData] = useState({});
    const [videos, setVideos] = useState([]);
    //const [titleLines, setTitleLines] = useState([]);
    const [title, setTitle] = useState([]);  // multi-line 일 수 있음 
    const [videoDirection, setVideoDirection] = useState(null);
    //const { scrollVisibility } = useContext(VisibilityContext);

    useEffect(() => {
        if (!props.settings) return;
        for (const item of props.settings) {
            if (item.block === 2) {
                if (item.title && item.title.length > 1) {
                    setTitle(item.title.split("\n"));
                } else {
                    setTitle([]);
                }
            }
            if ("videoDirection" in item) {
                setVideoDirection(item.videoDirection);                
            }
        }
    }, [props.settings]);

    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/api/v1/landings/block/2`, null,
            (resp) => {
                //console.dir(resp.data);
                setData(resp.data.data[0]);
                //setTitleLines(resp.data.data[0].title.split("\n"));
            },
            (err) => {
                console.error(err);
            }
        );
    }, []);

    useEffect(() => {
        if (!videoDirection) return;
        BoNetUtil.get(`${BACKEND_URL}/api/v1/landings`, { type: 'video', videoDirection: videoDirection, isActive: true},
            (resp) => {
                setVideos(resp.data.data);
            },
            (err) => {
                console.error(err);
            }
        );
    }, [videoDirection]);

    return (
        <div>
            <div className="text-left mx-3 mb-3 text-xl font-bold">
            {
                title.map((item, index) => <div key={index}>{item}</div>)
            }
            </div>
            <ScrollMenu>
                {
                    videos.map((video, id) => {
                        return (
                            <VideoItem video={video} data={data} key={id} id={id} count={videos.length} direction={videoDirection}/>
                        )
                    })
                }
            </ScrollMenu>
        </div>
    )
}

function VideoItem(props) {
    // props.video
    // props.direction
    const [stat, setStat] = useState({});
    const [vw, setVw] = useState("17rem");
    const [vh, setVh] = useState("9.6rem");

    useEffect(() => {
        if (!props.video?.videoUrl) return;
        axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${props.video.videoUrl}&key=AIzaSyCwCtKgY-eX5RcQ5ORZShfIx8D215wRXyg&part=statistics`)
        .then((resp) => {
            //console.dir(resp.data.items[0].statistics);
            setStat(resp.data.items[0].statistics);
        });
    }, [props.video]);

    useEffect(() => {
        if (props.direction === "vertical") {
            setVw("16rem");
            setVh("29rem");
        } else {
            setVw("17rem");
            setVh("9.6rem");
        }
    }, [props.direction]);

    const shareVideo = (e) => {
        navigator.share({
            title: '채권도시C',
            text: '오랜 금전 스트레스를 한방에 해결!',
            url: `https://www.youtube.com/embed/${props.video.videoUrl}`
        })
        .then( _ => console.log('Yay, you shared it :)'))
        .catch( error => console.log('Oh noh! You couldn\'t share it! :\'(\n', error));
    }

    return (
        <div className={classnames("flex justify-content-center", {"ml-3": props.id === 0}, {"ml-2": props.id !== 0}, {"mr-3": props.id === props.count-1})} >
            <div>
                <div className="rounded-video">
                    <ReactPlayer url={`https://www.youtube.com/embed/${props.video.videoUrl}`} light={true} width={vw} height={vh} playing={true}/>
                    {/*
                    <iframe src={`https://www.youtube.com/embed/${data.videoUrl}`} height="180" style={{ border: 0 }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen title={data.videoUrl}>
                    </iframe>
                    */}
                </div>
                <div className="p-3 text-700 text-sm flex justify-content-end align-items-center border-round-bottom-lg" style={{width: vw, backgroundColor: "#f5f4f3"}}>
                    <i className="pi pi-eye ml-2"/><span className="mx-1">{stat.viewCount}</span>
                    <i className="pi pi-heart ml-2"/><span className="mx-1">{stat.likeCount}</span>
                    <i className="pi pi-comments ml-2"/><span className="mx-1">{stat.commentCount}</span>
                    <i className="pi pi-share-alt ml-2" onClick={shareVideo}/>
                </div>
            </div>
        </div>
    );
}
export function Block3(props) {

    const [data, setData] = useState({});
    const [upperBanner, setUpperBanner] = useState({});
    const [bottomBanner, setBottomBanner] = useState({});
    const [title3, setTitle3] = useState([]);  // multi-line 일 수 있음 
    const [title4, setTitle4] = useState([]);  // multi-line 일 수 있음     

    useEffect(() => {
        if (!props.settings) return;
        for (const item of props.settings) {
            if (item.block === 3) {
                if (item.title && item.title.length > 1) {
                    setTitle3(item.title.split("\n"));
                } else {
                    setTitle3([]);
                }
            } else if (item.block === 4) {
                if (item.title && item.title.length > 1) {
                    setTitle4(item.title.split("\n"));
                } else {
                    setTitle4([]);
                }
            }
        }
    }, [props.settings]);

    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/api/v1/landings/block/3`, null,
            (resp) => {
                //console.dir(resp.data);
                setData(resp.data.data[0]);
            },
            (err) => {
                console.error(err);
            }
        );
    }, []);
    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/api/v1/landings`, { type: 'upper_banner', isActive: true },
            (resp) => {
                setUpperBanner(resp.data.data[0]);
            },
            (err) => {
                console.error(err);
            }
        );
    }, []);
    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/api/v1/landings`, { type: 'bottom_banner', isActive: true },
            (resp) => {
                setBottomBanner(resp.data.data[0]);
            },
            (err) => {
                console.error(err);
            }
        );
    }, []);

    return (
        <div className="text-left">
            <div className="text-left mx-3 mb-3 text-xl font-bold">
            {
                title3.map((item, index) => <div key={index}>{item}</div>)
            }
            </div>
            <WideBanner key="upper" data={upperBanner} />
            <WideButton text="채권 등록을 시작하세요" color="white" bgcolor="#0a174e" showKakaoChannelGuide={props.showKakaoChannelGuide}/>
            {/*<WideButton text="채권 등록을 시작하세요" color="white" bgcolor="#0a174e" />*/}
            {/*<h4 className="text-lg px-3">{data.title}</h4>*/}
            <div className="text-left mx-3 mb-3 text-xl font-bold">
            {
                title4.map((item, index) => <div key={index}>{item}</div>)
            }
            </div>
            <WideBanner key="bottom" data={bottomBanner} />
        </div>
    );
}

export function WideBanner(props) {
    // props.data
    const [titleLines, setTitleLines] = useState([]);
    const [subLines, setSubLines] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.data?.title) {
            setTitleLines(props.data.title.split("\n"));
        }
        if (props.data?.subTitle) {
            setSubLines(props.data.subTitle.split("\n"));
        }
    }, [props.data]);

    const clickBanner = () => {
        if (!!!props.data?.link) return;
        if (props.data.link.startsWith("http")) {
            window.location.href = props.data.link;
        } else {
            navigate(props.data.link);
        }
    }

    if (!props.data) {
        return null;
    }

    return (
        <div style={{ backgroundColor: props.data?.color || "lightgray", color: props.data?.fontColor || "black", height: "11rem" }} 
            className={classnames("my-2 mx-3 p-3 border-round-lg relative", {"cursor-pointer": !!props.data?.link})}
            onClick={() => clickBanner()}>
            <h5 className="text-lg m-1">
                {titleLines.map((line, index) => <div key={index}>{line}</div>)}
            </h5>
            <div className="mx-1 my-2">
                {subLines.map((line, index) => <div key={index}>{line}</div>)}
            </div>
            <img src={props.data?.imageUrl} className="absolute bottom-0 right-0 mr-2 mb-1" style={{ maxWidth: "10rem", maxHeight: "9rem" }} />
        </div>
    );
}

export function WideButton(props) {
    // props.text
    // props.color
    // props.bgcolor
    const navigate = useNavigate();

    const gotoChat = (e) => {
        if (window.location?.host.startsWith('2ai')) {
            alert('시스템 점검 중입니다. ');
            return;
        }
        navigate("/chat");
    }

    return <div style={{backgroundColor: "#f5f4f3"}} className="px-0 py-2 my-3">
        <div style={{backgroundColor: props.bgcolor, height: "80px"}}
             className="my-3 mx-3 px-3 border-round-lg flex align-items-center cursor-pointer"
            // onClick={() => navigate("/chat")}>
             onClick={
                 // gotoChat
                 props.showKakaoChannelGuide ? props.showKakaoChannelGuide : goToKakaoChannel
             }>
            <div className="m-1 font-bold text-lg" style={{color: props.color}}>{props.text}</div>
            {/*<div className="ml-auto text-white py-2 px-3 border-round" style={{backgroundColor: "#F59000"}}>등록</div>*/}
        </div>
    </div>;
}

function Represent(props) {
    // props.settings

    const [represents, setRepresents] = useState([]);

    useEffect(() => {
        // https://vatdahm.com/api/v1/landings?type=bottom_represent
        /*
        {
            "_id": "654645ead33a7d1a71825ed3",
            "title": "추심해서 행복해요 ㅎㅎ",
            "nickName": "사슴쟁이",
            "bondType": "이혼 위자료",
            "contents": "나쁜놈한테 사기결혼당해서 ㅜㅜ 위자료 받았어요",
            "index": 2,
            "isActive": true,
            "type": "bottom_represent",
            "tags": [
                "#행복해요",
                " "
            ],
            "block": 2,
            "createdAt": "2023-11-04T13:23:54.459Z",
            "modifiedAt": "2023-11-04T13:23:54.459Z",
            "likeCount": [
                "654b9cefe88ae696d7d1aaa9"
            ]
        }
        */
        BoNetUtil.get(`${BACKEND_URL}/api/v1/landings`, {type: "bottom_represent", isActive: true},
            (resp) => {
                setRepresents(resp.data.data);
            },
            (err) => console.error(err)
        );
    }, []);
    return (
        <div className="my-3">
            <ScrollMenu>
                {
                    represents.map((item, index) => {
                        return (
                            <Review review={item} key={index} id={index} count={represents.length}/>
                        )
                    })
                    
                }
            </ScrollMenu>            
        </div>
    );
}

function Review(props) {
    // props.review
    // props.key
    // props.id
    // props.count
    const [likeCount, setLikeCount] = useState(0);

    useEffect(() => {
        //TODO: 임시조치임. 실제로 likeCount는 이런식으로 이상하게 날아옴 
        /*
        "likeCount": [
            "654b9cefe88ae696d7d1aaa9"
        ]
        */
        if ("likeCount" in props.review) {
            //2311 gary setLikeCount(parseInt(props.review?.likeCount[0]) % 1000);
            setLikeCount(props.review?.likeCount?.length || 0);
        } else {
            setLikeCount(0);
        }
    }, [props.review]);

    return (
        <div className={classnames("border-round-lg p-3 surface-200 mb-1", {"ml-3": props.id === 0}, {"ml-2": props.id !== 0}, {"mr-3": props.id === props.count-1})} 
            style={{width: "19rem", height: "12rem"}} key={props.id} >       
            <div className="flex flex-column h-full">
                <div className="text-left">
                    <Tag value="BEST" className="font-normal text-white" style={{ backgroundColor: "#FF4545"}} />
                    <span className="ml-2 text-lg">{props.review?.title}</span>
                </div>
                <div className="flex mt-2">
                    <div>{props.review?.nickName}</div>
                    <div className="ml-auto">{props.review?.bondType}</div>
                </div>
                <div className="text-left mt-2 text-sm text-600" style={{height: "2.8rem"}}>
                    {props.review?.contents}
                </div>
                <div className="mt-auto flex">
                    <div className="text-sm text-700">{props.review?.tags.map((item, index) => <span key={index}>{`#${item} `}</span>)}</div>
                    <div className="ml-auto text-sm text-700"><i className="pi pi-thumbs-up"/> {likeCount}</div>
                </div>
            </div> 
        </div>
    );
}

export function Footer(props) {
    const navigate = useNavigate();

    const [firmData, setFirmData] = useState({});

    useEffect(() => {
        // https://vatdahm.com/api/v1/lawFirms
        if (window.location?.host.startsWith('m.')) {
            setFirmData({name:"채권도시M", ceo:"이현우", cso:"이현우", registerNumber:"754-81-02254", email:"creditncity@gmail.com", ename:"creditncityM office", address:"서울특별시 강남구 영동대로85길 34  스파크플러스 삼성2호점 801호, 06180", telemarketingNumber:""});
        }else {

            BoNetUtil.get(`${BACKEND_URL}/api/v1/lawFirms`, null,
                (resp) => {
                    setFirmData(resp.data.data[0]);
                },
                (err) => console.error(err)
            );
        }
    }, [])

    return (
        <div className="text-left p-3" style={{backgroundColor: "#F5F4F3", color:"#888888"}}>
            <br/>
            <div className="text-sm">{firmData.name}</div>
            <br/>
            <div className="text-xs">대표자: {firmData.ceo}</div>
            <div className="text-xs">개인정보보호책임자: {firmData.cso}</div>
            {!window.location?.host.startsWith('2ai') &&
            <div className="text-xs">사업자등록번호: {firmData.registerNumber}</div>
            }
            {!window.location?.host.startsWith('2ai') &&
            <div className="text-xs">통신판매업신고번호: {firmData.telemarketingNumber}</div>
            }
            <div className="text-xs">{firmData.address}</div>
            <div className="text-xs">이메일: {firmData.email}</div>
            <br/>
            <div className="text-xs">Copyright {firmData.ename}. All rights reserved.</div>
            <br/>
            <div className="text-xs">
                {/*<span className="mr-3 cursor-pointer" onClick={() => navigate("/company")}>회사소개</span>*/}
                <span className="mr-3 cursor-pointer" onClick={() => navigate("/terms")}>이용약관</span>
                <span className="mr-3 cursor-pointer" onClick={() => navigate("/privacy")}>개인정보처리방침</span>
            </div>
            <br/>
            <div>
                <img src="/assets/android-gray.svg" className="mr-2"/>
                <img src="/assets/ios-gray.svg" className="mr-2"/>
                <img src="/assets/facebook-gray.svg" className="mr-2"/>
                <img src="/assets/instagram-gray.svg" className="mr-2"/>
                <img src="/assets/naver-gray.svg" className="mr-2"/>
            </div>
        </div>
    );
}