import React, {useState, useEffect} from "react";
import useAxios from "../../hooks/useAxios";
import {Table, Input, message, Select, Flex, Space, Button} from "antd";
import {EmptyBox, FlexColumn, GrayBox, Title3} from "../../common/DeskComponent";
import PublicFileUploader from "../../components/PublicFileUploader";
import {PlusOutlined} from "@ant-design/icons";
import {isMobile} from "react-device-detect";


//setGrpYmAnswers이나 saveGrpAnswer=null 이면 readOnly모드로 활용가능.
//List< List<YmAnswer>> grpYmAnswers; 가 파라미터로 좋을지? cm이 좋을지 저장시 고민필요.
const InfoTellingGrpQnA = ({grpIdx, isFinalGrp, commonYmq, ymq,
                               grpYmAnswers, setGrpYmAnswers, saveGrpAnswer,
                               reRequestFlag, setReRequestKeySet, OP_VIEW_MODE}) => {

    const [messageApi, contextHolder] = message.useMessage();
    //const [grpYmAnswers, setGrpYmAnswers] = useState(paramGrpYmAnswers?paramGrpYmAnswers:[]);
    //grpYmAnswers[0][0] 관련해서는 CreditMaster.java의 코멘트 참조:  grpYmAnswers = [ ymAs(그룹0-신상채권자), ymAs(그룹1-신상채무자),  ymAs(그룹2), ymAs(그룹3) ]
    const [creditorGrpIdx, setCreditorGrpIdx] = useState( (Array.isArray(grpYmAnswers) && Array.isArray(grpYmAnswers[0])) ? grpYmAnswers[0][0]?.creditorDebtorGrpIdx : undefined);
    const [debtorGrpIdx, setDebtorGrpIdx] = useState((Array.isArray(grpYmAnswers) && Array.isArray(grpYmAnswers[1])) ? grpYmAnswers[1][0]?.creditorDebtorGrpIdx : undefined);

    const [changedGrpIdxSet, setChangedGrpIdxSet] = useState(new Set());

    const commonColumns = [
        {title: '질문(법률사무소 필요정보)', key: '질문', dataIndex: '질문', width: 300,},
        {title: '정보 입력란', key: '예시', dataIndex: '예시', width: 400, //commonGrpIdx:0,1은 채권자.  commonGrpIdx:2,3은 채무자 질문임. ->  savingGrpIdx는 0, 1로 변경해야함.
            render:(text,record,idx) => <Input type={'text'} readOnly={!saveGrpAnswer}  // idx는 질문의 idx임, record.grpIdx(질문grpIdx)로 savingCommonGrpIdx 변환사용필요.
                                               disabled={userReplyMode}
                                               value = {(Array.isArray(grpYmAnswers) && Array.isArray(grpYmAnswers[getSavingComGrpIdx(record.grpIdx)])) ? grpYmAnswers[getSavingComGrpIdx(record.grpIdx)][idx]?.answer : undefined}
                // onChange={e=>handleCommonTextChange(record.grpIdx, idx, e)} name={record.질문} style={{width:'100%'}} placeholder={text}/> ,
                                               onChange={e=>handleTextChange(getSavingComGrpIdx(record.grpIdx), idx, e)} name={record.질문} style={{width:'100%'}} placeholder={text}/> ,
        },
    ];
    const columns = [
        {title: '질문(법률사무소 필요정보)', key: '질문', dataIndex: '질문', width: 300, },
        {title: '상세 설명', key: '설명', dataIndex: '설명', width: 300,},
        {title: '정보 입력란', key: '예시', dataIndex: '예시', width: 300,
            render:(text,record,idx) => record.fileAttach?
                <FlexColumn>
                    {Array.isArray(grpYmAnswers) && grpYmAnswers.length > record.grpIdx +1 && grpYmAnswers[record.grpIdx + 2] && Array.isArray(grpYmAnswers[record.grpIdx + 2][idx]?.fileAttaches) && grpYmAnswers[record.grpIdx + 2][idx].fileAttaches.map((v, fileIdx) =>
                        <PublicFileUploader imageUrl={v} downloadOnly={opMode}
                                            downloadName={'첨부파일'+(fileIdx+1)} //downloadOnly={replyExist}
                                            onChange={(returnUrl) => onSetFileAttach(record.grpIdx + 2, idx, fileIdx, returnUrl)}/>
                    )}

                    {!opMode &&
                        <Button type={'primary'} onClick={()=> onAddFileAttach(record.grpIdx + 2, idx)} icon={<PlusOutlined/>}  style={{marginTop:8}}>
                            파일추가
                        </Button>
                    }
                </FlexColumn>
                :
                <Input.TextArea readOnly={!saveGrpAnswer} //grpYmAnswers의 3번째(grpIdx=2)부터 저장됨.
                                disabled={userReplyMode}
                                value = {(Array.isArray(grpYmAnswers) && Array.isArray(grpYmAnswers[record.grpIdx + 2])) ? grpYmAnswers[record.grpIdx + 2][idx]?.answer : undefined}
                                onChange={e=>handleTextChange(record.grpIdx + 2, idx, e)} name={'예시'} style={{width:'100%'}} placeholder={text?('예시)'+text):''}/> ,
        },
    ];

    //code 간소화를 위해 mode 추가.////////////
    const opMode = !saveGrpAnswer;
    const userReplyMode = saveGrpAnswer && reRequestFlag;

    //답변 개수 (OP용)
    const getRepliesAnswerCount = (replies) => {
        let count = 0;
        for (let reply of replies) {
            if (reply?.answer) count++;
        }
        return count;
    }

    //답변이 없는 최초 index찾기.(OP용)
    const getRepliesNoAnswerIndex = (replies) => {
        for (let i = 0; i< replies.length; i++) {
            if (replies[i] && replies[i].request && !replies[i].answer) return i;
        }
        return -1;
    }

    //마지막 request index (USER_REPLY용)
    const getReplieLastRequestIndex = (replies) => {
        let ret=0;
        for (let i = 0; i< replies.length; i++) {
            if (replies[i] && replies[i].request) ret = i;
        }
        // return ret; => 마지막에 답변이존재시,  이전에 이미 답변한 것이므로  무시해야 하는데.. 어려워서 일단 무시.
        return ret;
    }


    //(USER_REPLY모드 제출시 validation 체크용)
    const getRepliesNoAnswerCount = (replies) => {
        let count = 0;
        for (let i = 0; i< replies.length; i++) {
            if (replies[i] && replies[i].request && !replies[i].answer) count++;
        }
        return count;
    }

    //savingGrpIdx = cm의 index
    const REPLY_TAG = (savingGrpIdx, idx) =>  <>
        { //let ymAnswers = grpYmAnswers[savingGrpIdx]
            //let reply =  ymAnswers[idx].reply;
            (opMode && (
                (grpYmAnswers.length > savingGrpIdx && grpYmAnswers[savingGrpIdx] && grpYmAnswers[savingGrpIdx][idx] && Array.isArray(grpYmAnswers[savingGrpIdx][idx].replies) && grpYmAnswers[savingGrpIdx][idx].replies.length > 0) ?
                    <>
                        {Array.from({length:getRepliesAnswerCount(grpYmAnswers[savingGrpIdx][idx].replies)}).map((_, i) => <>
                            <div> 요청: {grpYmAnswers[savingGrpIdx][idx].replies[i].request}</div>
                            <div style={{fontWeight:900, marginBottom:8}}> 답변: {grpYmAnswers[savingGrpIdx][idx].replies[i].answer}</div>
                        </>)
                        }
                        { getRepliesNoAnswerIndex(grpYmAnswers[savingGrpIdx][idx].replies) >= 0 ? //TODO 0 을 getRepliesNoAnswerIndex 로 변경 TEST중.
                            <Input.TextArea //value={grpYmAnswers[savingGrpIdx][idx].replies[ 0 ].request}
                                placeholder={'재요청 내용을 입력:'}
                                value={grpYmAnswers[savingGrpIdx][idx].replies[ getRepliesNoAnswerIndex(grpYmAnswers[savingGrpIdx][idx].replies) ].request}
                                onChange={e => handleReplyRequestChange(savingGrpIdx, idx, e, getRepliesNoAnswerIndex(grpYmAnswers[savingGrpIdx][idx].replies) )}
                            />
                            :
                            (OP_VIEW_MODE)? <></> : <Button size={'small'} onClick={()=> onAddReplyRequest(savingGrpIdx, idx) }> 재재요청 추가</Button>
                        }
                    </>
                    :
                    (OP_VIEW_MODE)? <></> : <Button size={'small'} onClick={()=> onAddReplyRequest(savingGrpIdx, idx) }> 재요청 추가</Button>
            ))
        }
        { (userReplyMode && (
            (grpYmAnswers.length > savingGrpIdx && grpYmAnswers[savingGrpIdx] && grpYmAnswers[savingGrpIdx][idx] && Array.isArray(grpYmAnswers[savingGrpIdx][idx].replies) && grpYmAnswers[savingGrpIdx][idx].replies.length > 0) ?
                <>
                    {Array.from({length:getReplieLastRequestIndex(grpYmAnswers[savingGrpIdx][idx].replies)}).map((_, i) => <>
                        <div> 이전요청: {grpYmAnswers[savingGrpIdx][idx].replies[i].request}</div>
                        <div style={{marginBottom:8}}> 이전답변: {grpYmAnswers[savingGrpIdx][idx].replies[i].answer}</div>
                    </>)
                    }
                    <div style={{fontWeight:900}}> 재요청: {grpYmAnswers[savingGrpIdx][idx].replies[ getReplieLastRequestIndex(grpYmAnswers[savingGrpIdx][idx].replies) ].request} </div>
                    <Input.TextArea value={grpYmAnswers[savingGrpIdx][idx].replies[ getReplieLastRequestIndex(grpYmAnswers[savingGrpIdx][idx].replies) ].answer} placeholder={'답변 입력:'}
                                    onChange={e => handleReplyAnswerChange(savingGrpIdx, idx, e, getReplieLastRequestIndex(grpYmAnswers[savingGrpIdx][idx].replies))}
                    />
                </>
                :
                <></>
        ))
        }

    </>;

    //opViewer모드 or 고객재입력 모드시
    if (userReplyMode || opMode) {

        commonColumns.push({title: '재요청 입력란', key: '질문', dataIndex: '질문', width: 500,
            render:(text,record,idx) => <>
                {/*<Button size={'small'} onClick={()=> onAddReplyRequest(getSavingComGrpIdx(record.grpIdx), idx) }> 재요청 추가</Button>*/}
                {REPLY_TAG(getSavingComGrpIdx(record.grpIdx), idx)}
            </>
        });
        columns.push({title: '재요청 입력란', key: '질문', dataIndex: '질문', width: 500,
            render:(text,record,idx) => <>
                {/*<Button size={'small'} onClick={()=> onAddReplyRequest(record.grpIdx + 2, idx) }> 재요청 추가</Button>*/}
                {REPLY_TAG(record.grpIdx + 2, idx)}
            </>
        });
    }

    const onAddReplyRequest = (savingGrpIdx, idx) => {
        let grpIdx = (savingGrpIdx == 0 || savingGrpIdx == 1)? 0: savingGrpIdx - 1;

        //메인에서 개수관리.
        setReRequestKeySet(prev => new Set(prev).add( savingGrpIdx + "." + idx));

        setGrpYmAnswers(prev => {
            let ret = [...prev];
            let ymAnswers = ret[savingGrpIdx]?  ret[savingGrpIdx]:[];
            let oldReplies = ymAnswers[idx]? ymAnswers[idx].replies:[];
            ymAnswers[idx] = {...ymAnswers[idx], replies:[...oldReplies, {request:' ', answer:undefined}]};
            ret[savingGrpIdx]  = ymAnswers;
            return ret;
        })
    }

    const handleReplyRequestChange = (savingGrpIdx, idx, e, newIndex) => {
        console.log('handleReplyRequestChange value:', e.target.value);
        if (!e.target.value) return;

        setGrpYmAnswers(prev => {
            let ret = [...prev];
            let ymAnswers = ret[savingGrpIdx]?  ret[savingGrpIdx]:[];
            let oldReplies = ymAnswers[idx]? ymAnswers[idx].replies:[];
            //oldReplies[0].request = e.target.value;
            oldReplies[newIndex].request = e.target.value;

            ymAnswers[idx] = {...ymAnswers[idx], replies:oldReplies};
            ret[savingGrpIdx]  = ymAnswers;
            console.log('handleReplyRequestChange:', ret);
            return ret;
        })
    }
    const handleReplyAnswerChange = (savingGrpIdx, idx, e, newIndex) => {
        setGrpYmAnswers(prev => {
            let ret = [...prev];
            let ymAnswers = ret[savingGrpIdx]?  ret[savingGrpIdx]:[];
            let oldReplies = ymAnswers[idx]? ymAnswers[idx].replies:[];
            // oldReplies[0].answer = e.target.value;
            oldReplies[newIndex].answer = e.target.value;

            ymAnswers[idx] = {...ymAnswers[idx], replies:oldReplies};
            ret[savingGrpIdx]  = ymAnswers;
            console.log('handleReplyRequestChange:', ret);
            return ret;
        })
    }

    const onAddFileAttach = (savingGrpIdx, idx) => {
        setGrpYmAnswers(prev => {
            let ret = [...prev];
            let ymAnswers = ret[savingGrpIdx]?  ret[savingGrpIdx]:[];
            // console.log('onAddFileAttach 1:', ymAnswers);
            let oldFileAttaches = (ymAnswers[idx] && Array.isArray(ymAnswers[idx].fileAttaches))? ymAnswers[idx].fileAttaches:[];
            // console.log('onAddFileAttach 2:', oldFileAttaches);
            oldFileAttaches.push(undefined);
            // console.log('onAddFileAttach 3:', oldFileAttaches);

            ymAnswers[idx] = {...ymAnswers[idx], fileAttaches:oldFileAttaches}; //다른데 들어감.
            // console.log('onAddFileAttach 4 ymAns:', ymAnswers[idx]);

            ret[savingGrpIdx]  = ymAnswers;
            // console.log('onAddFileAttach:', ret);
            return ret;
        })
    }
    const onSetFileAttach =(savingGrpIdx, idx, fileIdx, returnUrl) => {
        setGrpYmAnswers(prev => {
            let ret = [...prev];
            let ymAnswers = ret[savingGrpIdx]?  ret[savingGrpIdx]:[];
            let oldFileAttaches = (ymAnswers[idx] && Array.isArray(ymAnswers[idx].fileAttaches))? ymAnswers[idx].fileAttaches:[];
            oldFileAttaches[fileIdx] = returnUrl;
            ymAnswers[idx] = {...ymAnswers[idx], fileAttaches:oldFileAttaches}; //다른데 들어감.
            ret[savingGrpIdx]  = ymAnswers;
            console.log('onAddFileAttach:', ret);
            return ret;
        })
    }



    //commonGrpIdx:0,1은 채권자.  commonGrpIdx:2,3은 채무자 질문임. ->  savingGrpIdx는 0, 1로 변경해야함.
    const getSavingComGrpIdx = (commonGrpIdx) => {
        return commonGrpIdx<=1? 0:1;
    }

    // handleTextChange 와 같은 상태.
    // const handleCommonTextChange = (savingComGrpIdx, idx, e) =>{
    //     //let savingGrpIdx = commonGrpIdx<=1? 0:1; //grpIdx:0,1은 채권자.  grpIdx:2,3은 채무자 질문임. ->  savingGrpIdx는 0, 1로 변경해야함.
    //     setGrpYmAnswers(prev => {
    //         let ret = [...prev];
    //         let ymAnswers = ret[savingComGrpIdx]?  ret[savingComGrpIdx]:[];
    //         ymAnswers[idx] = e.target.value;
    //         ret[savingComGrpIdx]  = ymAnswers;
    //         return ret;
    //     })
    // }
    const handleTextChange = (savingGrpIdx, idx, e) =>{
        //임시저장 색상용: 역산 grpIdx:
        let grpIdx = (savingGrpIdx == 0 || savingGrpIdx == 1)? 0: savingGrpIdx - 1;
        setChangedGrpIdxSet(prev => new Set(prev).add(grpIdx));


        setGrpYmAnswers(prev => {
            let ret = [...prev];
            let ymAnswers = ret[savingGrpIdx]?  ret[savingGrpIdx]:[];
            ymAnswers[idx] = {...ymAnswers[idx], answer: e.target.value};
            ret[savingGrpIdx]  = ymAnswers;
            return ret;
        })
    }

    //value:0 or 1
    const onChangeCreditorGrpIdx = (value) => {
        setCreditorGrpIdx(value);
        const savingGrpIdx = 0
        setGrpYmAnswers(prev => {
            let ret = [...prev];
            let ymAnswers = ret[savingGrpIdx]?  ret[savingGrpIdx]:[];
            ymAnswers[0] = { ...ymAnswers[0],  creditorDebtorGrpIdx: value  }; //0번째 REcord에 하드코딩 저장.
            ret[savingGrpIdx]  = ymAnswers;
            return ret;
        })

    }

    //value:2 or 3
    const onChangeDebtorGrpIdx = (value) => {
        setDebtorGrpIdx(value);
        const savingGrpIdx = 1;
        setGrpYmAnswers(prev => {
            let ret = [...prev];
            let ymAnswers = ret[savingGrpIdx]?  ret[savingGrpIdx]:[];
            ymAnswers[0] = { ...ymAnswers[0],  creditorDebtorGrpIdx: value  }; ////0번째 REcord에 하드코딩 저장.
            ret[savingGrpIdx]  = ymAnswers;
            return ret;
        })
    }

    const onTempSave = async (grpIdx) => {

        //로그확인용.
        let savingGrpIdxs = [];
        if (grpIdx == 0) {
            savingGrpIdxs = [0,1];
        }else {
            savingGrpIdxs = [grpIdx+1];
        }
        //신상질문만 그룹 2개 확인.  나머진 1개만 확인.
        for (let savingGrpIdx of savingGrpIdxs) {
            console.log(`임시저장 grpYmAnswers[${savingGrpIdx}]`, grpYmAnswers[savingGrpIdx]);
        }

        //일뢀 한번에 저장. : grpIdx미사용.
        let res = await saveGrpAnswer(false, -1, grpYmAnswers);
        console.log( 'onTempSave', res);

        if (res?.code >= 0) {
            setChangedGrpIdxSet(prev => {
                const newSet = new Set(prev); //ERR.delete(grpIdx)
                newSet.delete(grpIdx);
                return newSet;
            });

            messageApi.info('임시 저장되었습니다.')
        }else {
            alert("저장 오류:" + res?.msg);
        }
    }

    const saveAllGrpAnswer = async() => {

        if (userReplyMode) { //재요청 답변을 모두 입력했는지 확인해야함. (OP모드에서 안정성을 위해 꼭 필요)
            let totalNoAnswerCount = 0;
            for (let grpIdx = 0; grpIdx < grpYmAnswers.length; grpIdx++) {
                console.log( `grpIdx:${grpIdx} `, grpYmAnswers[grpIdx]);

                if (Array.isArray(grpYmAnswers[grpIdx])) {
                    for (let idx = 0; idx < grpYmAnswers[grpIdx].length; idx++) { //2차원 배열임 : 질문 idx. (columns에 들어오는 idx필요)
                        //답변이 하나도 없으면 grpYmAnswers[grpIdx][idx]가 null일수 있음.
                        if (grpYmAnswers[grpIdx][idx])
                            totalNoAnswerCount += getRepliesNoAnswerCount(grpYmAnswers[grpIdx][idx].replies);
                    }
                }
            }

            console.log(`uerReplyMode 답변제출- 미답변개수: ${totalNoAnswerCount}`);
            if (totalNoAnswerCount > 0) {
                alert(`답변하지 않은 재요청이 존재합니다. 미답변개수:${totalNoAnswerCount}`);
                return;
            }
        }


        saveGrpAnswer(true, -1, grpYmAnswers);
    }


    return(
        <>
            <GrayBox>
                {contextHolder}
                {grpIdx == 0?  //채권자, 채무자 :  질문grpIdx=0이면 -> savingGrpIdx 는 0,1.  질문grpIdx=1 이면 savingGrpIdx=2.
                    <>
                        <Flex style={{marginBottom:16}}><Space>
                            <Title3> 채권자 정보 : </Title3>
                            <Select value={creditorGrpIdx} disabled={!saveGrpAnswer || userReplyMode}
                                    onChange={v => onChangeCreditorGrpIdx(v)}
                                    placeholder={'선택하세요'} style={{width:120,  marginTop:11}} options={[{label:"개인", value:0}, {label:"법인", value:1}]}/>
                        </Space></Flex>
                        { creditorGrpIdx != undefined &&
                            <Table size="small" style={{marginBottom:16}} dataSource={commonYmq?.grpYmQuestions[creditorGrpIdx]} columns={commonColumns} pagination={false}/>
                        }

                        <br/>

                        <Flex style={{marginBottom:16}}><Space>
                            <Title3> 채무자 정보 : </Title3>
                            <Select value={debtorGrpIdx} disabled={!saveGrpAnswer || userReplyMode}
                                    onChange={v => onChangeDebtorGrpIdx(v)}
                                    placeholder={'선택하세요'} style={{width:120,  marginTop:12}} options={[{label:"개인", value:2}, {label:"법인", value:3}]}/>
                        </Space></Flex>
                        {debtorGrpIdx != undefined &&
                            <Table size="small"  style={{marginBottom:16}} dataSource={commonYmq?.grpYmQuestions[debtorGrpIdx]} columns={commonColumns} pagination={false}/>
                        }
                    </>
                    :
                    //ymq는 grpIdx-1에 질문 있음.
                    <Table dataSource={ymq?.grpYmQuestions[grpIdx-1]} columns={columns} pagination={false}/>
                }

                {saveGrpAnswer && !userReplyMode &&
                    <EmptyBox>
                        <Button disabled={ ![...changedGrpIdxSet].includes(grpIdx) } style={{marginTop:32, marginBottom:-32, backgroundColor: ![...changedGrpIdxSet].includes(grpIdx)?'lightgray':'#ffd118', color: 'black'}} type={'primary'}
                                onClick={()=>onTempSave(grpIdx)}> 임시저장 </Button>
                    </EmptyBox>
                }
            </GrayBox>

            {isFinalGrp && saveGrpAnswer &&
                <EmptyBox>
                    <Button style={{marginTop:32, marginBottom:-32, backgroundColor: '#ffd118', color: 'black'}} type={'primary'}
                            onClick={()=>saveAllGrpAnswer(true)}> 전체저장 / 제출 </Button>
                </EmptyBox>
            }

            {isMobile &&
                <div><br/><br/><br/><br/><br/></div>
            }
        </>
    )


}

export default InfoTellingGrpQnA;
