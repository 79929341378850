import React, {useState, useEffect} from "react";
import {BACKEND_URL, BoTokenAtom, BoUserIdAtom, BoUserTypeAtom} from "../../Atoms";
import {useRecoilState, useRecoilValue} from "recoil";
import useAxios from "../../hooks/useAxios";
import B2bLayout, {B2bStatus} from "./B2bLayout";
import CreditUnitAdd from "./creditAdd/CreditUnitAdd";
import {message} from "antd";



const B2bHome = (props) => {

    const [boToken, setBoToken] = useRecoilState(BoTokenAtom);
    const boUserId = useRecoilValue(BoUserIdAtom);       //위 boToken에서 파생.
    const boUserType  = useRecoilValue(BoUserTypeAtom);  //anonymous  google kakao naver  b2b추가됨.

    const {axiosGet} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();

    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
        console.log('B2bMain- USE_EFFECT []:' + BACKEND_URL);
        console.log('B2bMain- USE_EFFECT []: boUserId:' + boUserId);
        console.log('B2bMain- USE_EFFECT []: boUserType:' + boUserType);

        //1. 로그인 필요.
        if (!boUserId || boUserType !== 'b2b') { //email로그인 체크 강화 필요. boUserType을 이용하던지.
            // 로그인이 안되어 있으면. login화면으로 이동.
            window.location.href = '/b2bLoginLanding';
        }else {
            //로그인 완료. 아래에서 처리.
        }
    }, []);


    useEffect( () => {
        if (boUserId && boUserType === 'b2b') {
            getUserInfo();
        }
    }, [boUserId])

    //user상태에 따라 분기.
    const getUserInfo = async() => {
        try {
            let userInfo = await axiosGet(`/vws/bo/user/info`, {username: boUserId});
            console.log('B2bHome userInfo:', userInfo);
            setUserInfo(userInfo);

            //alert(userInfo.b2bStatus);//test
            if (userInfo && userInfo.corpId === 'samchully') {
                window.location.href = '/b2b/samchully/consign';
            } else if (userInfo && userInfo.b2bStatus < B2bStatus.USER_PREDATA_INPUT_DONE) {
                window.location.href = '/b2bPredata';  //여기는 b2bLogin에서 바로가므로 필요없을것 같으나, 홈Logo클릭대비.

            } else if (userInfo && userInfo.b2bStatus < B2bStatus.ADMIN_PREDATA_CONFIRM) {
                window.location.href = '/b2bPredataWait';

            } else if (userInfo && userInfo.b2bStatus === B2bStatus.ADMIN_PREDATA_CONFIRM) {
                window.location.href = '/b2bPlanConfirm';

            } else if (userInfo && userInfo.b2bStatus < B2bStatus.ADMIN_CONTRACT_SEND_OK) {
                window.location.href = '/b2bContractWait';

            } else if (!userInfo) { //2403-hotFix : b2b 로기인되어있다가 logout된 사람.
                window.location.href = '/b2bLoginLanding';
            }
        } catch (e) {
            //FIXME refresh토큰 오류같아서 일단, 재로그인 허용: gary 2401, B2bLogin 30라인도 코멘트 처리.

            console.log('LOGIN ERROR? refresh토큰 오류?',e);
            //alert('다시 로그인 해 주세요.');
            messageApi.info('다시 로그인 해 주세요.');

            window.location.href = '/b2bLoginLanding';
        }
        // window.location.href = '/b2b/creditUnitAdd';
    }



    //1. 로그인 필요시 빈화면.(잠깐 출력됨)
    if (!boUserId || boUserType !== 'b2b' || !userInfo || (userInfo && userInfo.b2bStatus < B2bStatus.ADMIN_CONTRACT_SEND_OK )) {
        return (<></>);
    }

    return (
        <>
            {contextHolder}
            <B2bLayout showLNB={true} >
                {/* 채권등록 or 채권관리 page*/}
                <CreditUnitAdd/>
            </B2bLayout>
        </>
    )
}

export default B2bHome;