import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Descriptions, Input, Result, Skeleton, Space, Spin, Steps, Typography} from "antd";
import {CheckOutlined, LikeOutlined, UploadOutlined} from "@ant-design/icons"
import {useRecoilValue} from "recoil";
import {Footer} from "./MainPage";
import {addComma, BoNetUtil, isEmptyString} from "../common/Util";
import {BACKEND_URL, BoUserTypeAtom} from "../Atoms";
import axios from "axios";
import FeePayment from "./FeePayment";
import CreditorDebtorInfo from "./CreditorDebtorInfo";

import {isMobile} from 'react-device-detect';
import {getMobileOS, isKakaoTalkWebView} from "../common/Util"
import {FlexColumn} from "../common/DeskComponent";
import PaymentAgreeModule from "./PaymentAgreeModule";
import useAxios from "../hooks/useAxios";


const PAYMENT_STEP = 1; //gary추가. 기존엔 3


const calcFinishedStep = (preBond, contentsConfirmed) => {
    if (!preBond) return -1;

    if (!isEmptyString(preBond.feeDepositCheckedAt) || !!preBond.successfulFeePaymentIdx) {
        //return 3; //기존결제창.
        return PAYMENT_STEP ; //결제완료임.
    }

    // if (!isEmptyString(preBond.privacyPolicyAgreedAt)) {
    //     return 2;
    // }
    //
    if (!isEmptyString(preBond.contractSignedAt)) {
        //return 1;
        return 0; //동의 했어도, 0만 완료된 상태임.
    }

    return contentsConfirmed ? 0 : -1;
}

const PreBondPage = () => {
    const navigate = useNavigate();
    const {preBondId} = useParams();
    const {axiosGet, axiosPost} = useAxios();

    // 이미 결제한 고객인데, 가채권 늦게 생성한 - 초기 예외경우자들. - 결제확인만 완료했을 때 refresh 용도.
    const [refreshTime, setRefreshTime] = useState();

    const boUserType = useRecoilValue(BoUserTypeAtom);
    const logged = !boUserType || (boUserType !== 'b2b' && boUserType !== "anonymous");
    console.log('logined', logged, boUserType);

    const [initedSuccessfully, setInitedSuccessfully] = useState();

    const [inputCreditorName, setInputCreditorName] = useState();
    const [inputStatusCreditorName, setInputStatusCreditorName] = useState();

    const [preBond, setPreBond] = useState();

    const [lawyerFeeAndSupport, setLawyerFeeAndSupport] = useState();

    //2502-결제전 고객으로부터 입력받는 내용->원장생성시 복사필요. 2개는 기존필드 활용, 4개 추가.
    const [preCreditorName, setPreCreditorName] = useState();
    const [preDebtorName, setPreDebtorName] = useState();

    const [preCreditorPhone, setPreCreditorPhone] = useState(preBond?.preCreditorPhone);
    const [preDebtorPhone, setPreDebtorPhone] = useState(preBond?.preDebtorPhone);
    const [preDebtorAddr, setPreDebtorAddr] = useState(preBond?.preDebtorAddr);
    const [preDebtorEmail, setPreDebtorEmail] = useState(preBond?.preDebtorEmail);



    const [contentsConfirmed, setContentConfirmed] = useState(false);

    //signupUserIdx 가 있으면 한스텝 증가함:
    const finishedStep = calcFinishedStep(preBond, contentsConfirmed);
    console.log("init finishedStep =", finishedStep);

    const [currentStep, setCurrentStep] = useState(finishedStep+1);
    console.log("init currentStep =", currentStep);

    const [spinning, setSpinning] = useState(false);


    const [allAgreed, setAllAgreed] = useState(false);

    //signupUserIdx 세팅. 이때, 채권자/채무자 정보 등록 필요.
    const authorizePreBonds = (onSuccess, onFail) => {
        BoNetUtil.post(
            `${BACKEND_URL}/api/v1/prebonds/${preBondId}/authorize`,
            {creditorName : inputCreditorName},
            onSuccess, onFail
        );
    };

    useEffect(() => {
        if (isEmptyString(preBondId)) return;

        authorizePreBonds(
            (response) => {
                console.log("authorizePreBonds() <- response =", response);
                setInitedSuccessfully(true);
                setPreBond(response.data);
            },
            (err) => {
                console.error("authorizePreBonds() -> isAxiosError?", axios.isAxiosError(err), err);
                setInitedSuccessfully(!!err.response);
            }
        );
    }, [preBondId, refreshTime]);

    useEffect(() => {
        if (!preBond) return;

        const getLawyerFeeAndSupport = async() => {
            let ret = await axiosGet(`/apiOpen/b2c/lawyerFeeAndSupport/${preBond.bondAmount}/${preBond.deposit}`);
            if (ret) {
                setLawyerFeeAndSupport(ret);
            }
        }
        console.log("after setPreBond() -> finishedStep =", finishedStep);
        console.log("after setPreBond() -> currentStep =", currentStep);
        setCurrentStep(finishedStep + 1);

        getLawyerFeeAndSupport(); //"(수임료:xx만원, 지원금:xx만원)"

        if (!preCreditorName) {
            setPreCreditorName(preBond?.creditorName);
        }
        if (!preDebtorName) {
            setPreDebtorName(preBond?.debtorName);
        }
    }, [preBond]);




    const postPreCreditorDebtorData = async () => {

        //validation필요.
        if (!preCreditorPhone) {
            alert('채권자 휴대폰번호를 입력해 주세요.');
            return;
        }

        let regex = /^010[0-9]{8}$/;
        let validPhone =  regex.test(preCreditorPhone.replace(/-/g, ''));
        if (!validPhone) {
            alert('채권자 휴대폰 번호형식이 잘못되었습니다.')
            return;
        }

        if (!preDebtorName) {
            alert('채무자 이름을 입력해 주세요.');
            return;
        }

        let res = await axiosPost(`/api/v1/prebonds/${preBondId}/preCreditorDebtorData`, {preCreditorName, preDebtorName,  preCreditorPhone,preDebtorPhone,preDebtorAddr,preDebtorEmail  } );

        if (res?.code >=0) {
            setContentConfirmed(true);
            setCurrentStep(1);
        }else {
            alert('저장실패:' + res?.msg);
        }

    }

    const manuallyAuthorizeBonds = () => {
        if (isEmptyString(inputCreditorName)) {
            setInputStatusCreditorName("error");
            return;
        }

        setSpinning(true);
        authorizePreBonds(
            (response) => {
                console.log("authorizePreBonds() <- response =", response);
                alert("인증에 성공했습니다.");
                setPreBond(response.data);
                setSpinning(false);
            },
            (err) => {
                console.error("authorizePreBonds() -> isAxiosError?", axios.isAxiosError(err), err);
                if (!err.response) {
                    setInitedSuccessfully(false);
                } else {
                    alert("인증에 실패했습니다");
                }
                setSpinning(false);
            }
        );
    };

    const agreeToContract = () => {
        if (!preBond) return;

        setSpinning(true);
        BoNetUtil.post(
            `${BACKEND_URL}/api/v1/prebonds/${preBond.id}/contract/agreed`,
            null,
            (response) => {
                console.log("agreeToContract() <- response =", response);
                // alert("계약서에 동의하셨습니다");
                setPreBond(response.data);
                setSpinning(false);
            },
            (err) => {
                console.error("agreeToContract() -> isAxiosError?", axios.isAxiosError(err), err);
                alert("일시적인 오류가 발생하였습니다. 잠시 후에 다시 시도해주시기 바랍니다");
                setSpinning(false);
            }
        );
    };

    const agreeToPrivacyPolicy = () => {
        if (!preBond) return;

        setSpinning(true);
        BoNetUtil.post(
            `${BACKEND_URL}/api/v1/prebonds/${preBond.id}/privacypolicy/agreed`,
            null,
            (response) => {
                console.log("agreeToContract() <- response =", response);
                // alert("계약서에 동의하셨습니다");
                setPreBond(response.data);
                setSpinning(false);
            },
            (err) => {
                console.error("agreeToContract() -> isAxiosError?", axios.isAxiosError(err), err);
                alert("일시적인 오류가 발생하였습니다. 잠시 후에 다시 시도해주시기 바랍니다");
                setSpinning(false);
            }
        );
    };

    console.log('isMobile', isMobile);
    console.log('mobileOS', getMobileOS());
    console.log('isKakaoTalkWebView', isKakaoTalkWebView());

    const clickLogin = () => {
        console.log('clickLogin:', isMobile, getMobileOS(), isKakaoTalkWebView());

        if (isMobile && getMobileOS() === 'iOS' && isKakaoTalkWebView() ) {
            alert('우측하단 메뉴에서 [Safari로 열기]를 선택한 후, 로그인해주세요.')
            return;
        }

        navigate("/signin", {state: {returnUrl : `/pbs/${preBondId}`}});

    }



    return (
        <div>
            <header>
                <div className="flex px-3 py-3 border-bottom-1 align-items-center"
                     style={{borderColor: "#F5F4F3", color: "#191919"}}>
                    <div className="p-0 m-0 flex align-items-center">
                        <a href="/" onClick={() => navigate("/")}>
                            <img src={`/assets/logoC.png`} alt="채권도시C" style={{height: "3.1rem"}}/>
                        </a>
                    </div>
                    <div className="ml-auto flex align-items-center">
                        {/*<Typography.Title level={3}>상담내용 확인 및 결제</Typography.Title>*/}
                        <Typography.Title level={5} style={{marginTop:10}}>상담확인 결제</Typography.Title>
                    </div>
                </div>
            </header>


            {isEmptyString(preBondId) && (
                <div style={{marginTop:20, height: "Calc(100vh - 402px)", minHeight: 400}}>
                    <Result status="error"
                            title="유효하지 않은 URL"
                            subTitle="상담원에게 결제 URL을 다시 문의하시기 바랍니다"
                    />
                </div>
            )}



            {!isEmptyString(preBondId) && (
                <>
                    {!logged && (
                        <div style={{height: "Calc(100vh - 402px)", minHeight: 400}}>
                            <Result status="warning"
                                    title="로그인이 필요합니다"
                                    extra={ //(isMobile && getMobileOS() === 'iOS' && isKakaoTalkWebView())? <div onClick={()=>{}}></div> :
                                         <Button //className="col-3 orange-back text-center p-3 cursor-pointer" //어떻게든 url을 넣어야 사파리로 열기 옵션이 뜸.
                                                style={{marginLeft: "auto", marginRight: "auto"}}
                                                 disabled={isMobile && getMobileOS() === 'iOS' && isKakaoTalkWebView()}
                                                style={{backgroundColor: (isMobile && getMobileOS() === 'iOS' && isKakaoTalkWebView())?'gary':'#FFD118',
                                                        borderColor: (isMobile && getMobileOS() === 'iOS' && isKakaoTalkWebView())?'gary':'#FFD118'}}
                                                onClick={clickLogin}>
                                            의뢰인 로그인
                                         </Button>
                                    }
                            />
                        </div>
                    )}
                    {logged && (
                        <>
                            {initedSuccessfully === undefined && (
                                <div style={{height: "Calc(100vh - 402px)", minHeight: 400}}>
                                    <Skeleton active={true} style={{padding: "48px 32px"}} />
                                </div>
                            )}
                            {initedSuccessfully === false && (
                                <div style={{height: "Calc(100vh - 402px)", minHeight: 400}}>
                                    <Result status="error"
                                            title="시스템에 일시적인 문제 발생"
                                            subTitle="잠시 후에 다시 시도하시거나, 상담원에게 문의하기시 바랍니다"
                                    />
                                </div>
                            )}
                            {initedSuccessfully === true && (
                                <>
                                    {!preBond && (
                                        <div style={{height: "Calc(100vh - 402px)", minHeight: 400}}>
                                            <Space direction="vertical">
                                                <Typography.Title level={5}>인증을 위해, 상담원에게 알려주신 채권자명을 입력해주세요</Typography.Title>
                                                <Space.Compact size="large" style={{width: 370}}>
                                                    <Input placeholder="채권자명"
                                                           status={inputStatusCreditorName}
                                                           value={inputCreditorName}
                                                           onChange={ev => {
                                                               setInputStatusCreditorName();
                                                               setInputCreditorName(ev.target.value);
                                                           }}
                                                           onPressEnter={manuallyAuthorizeBonds}
                                                    />
                                                    <Button disabled={isEmptyString(inputCreditorName)}
                                                            onClick={manuallyAuthorizeBonds}>확인</Button>
                                                </Space.Compact>
                                            </Space>
                                        </div>
                                    )}
                                    {preBond && (
                                        <Space direction="vertical" style={{width: "100%", padding: "32px 10px"}}>
                                            <Steps size="small" style={{marginLeft:16, marginBottom:0}} //type="navigation" direction={"horizontal"} //style={{flexDirection:'row'}}
                                                   items={[
                                                        {
                                                            title: "내용 확인",
                                                            status: finishedStep >= 0 ? "finish" : undefined,
                                                        },
                                                        // {
                                                        //     title: "계약서",
                                                        //     status: finishedStep >= 1 ? "finish" : undefined,
                                                        //     disabled: finishedStep < 0
                                                        // },
                                                        // {
                                                        //     title: "개인정보처리",
                                                        //     status: finishedStep >= 2 ? "finish" : undefined,
                                                        //     disabled: finishedStep < 1
                                                        // },
                                                        {
                                                            title: "계약동의 및 결제",
                                                            status: finishedStep >= PAYMENT_STEP ? "finish" : undefined,
                                                            disabled: finishedStep < PAYMENT_STEP-1
                                                        },
                                                        {
                                                            title: "추가정보",
                                                            status: finishedStep >= PAYMENT_STEP+1 ? "finish" : undefined,
                                                            disabled: finishedStep < PAYMENT_STEP
                                                        }
                                                    ]}
                                                   current={currentStep}
                                                   onChange={val => setCurrentStep(val)}
                                            />

                                            {currentStep === 0 && (
                                                <Space direction="vertical" style={{width: "100%"}}>
                                                    {/*<Typography.Title level={5}>상담내용 확인 후, 채권자/채무자 정보를 입력해주세요.</Typography.Title>*/}
                                                    <div style={{textAlign:'left', fontSize:16, fontWeight:500, margin:8}}>상담내용 확인 후, 채권자/채무자 정보를 입력해주세요.</div>
                                                    <Descriptions size="small" bordered column={2}
                                                                  labelStyle={{width: 100, textAlign: "center"}}
                                                                  style={{textAlign: "left", marginBottom: 24}}>
                                                        {/*<Descriptions.Item label="채권자명">{preBond.creditorName}</Descriptions.Item>*/}
                                                        {/*<Descriptions.Item label="채무자명">{preBond.debtorName}</Descriptions.Item>*/}
                                                        <Descriptions.Item label="채권총액" span={2}>{addComma(preBond.bondAmount)}원</Descriptions.Item>
                                                        {/*<Descriptions.Item label="서비스종류" span={2}>{preBond.delegatedAffairs.title}</Descriptions.Item>*/}
                                                        <Descriptions.Item label={<span>착수금<br/>(VAT포함)</span>}> <>
                                                                                                                      {addComma(preBond.feeAmount)}원 <br/>
                                                                                                                      {Array.isArray(lawyerFeeAndSupport) &&
                                                                                                                          <div> {'('}수임료:{lawyerFeeAndSupport[0]}, <br/>
                                                                                                                                지원금:{lawyerFeeAndSupport[1]}{')'}
                                                                                                                          </div>
                                                                                                                      }
                                                                                                                  </>
                                                                                                                  </Descriptions.Item>
                                                        {preBond.bonusPercentage > 0 && (<Descriptions.Item label="성공보수">{addComma(preBond.bonusPercentage)}%</Descriptions.Item>)}
                                                        {preBond.bonusPercentage <= 0 && (<Descriptions.Item label="성공보수">없음</Descriptions.Item>)}
                                                    </Descriptions>

                                                    <div style={{textAlign:'left', fontSize:16, fontWeight:500, margin:8}}>채권자 정보 입력 (필수)</div>
                                                    <Descriptions size="small" bordered column={2}
                                                                  labelStyle={{width: 100, textAlign: "center"}}
                                                                  style={{textAlign: "left", marginBottom: 24}}>
                                                        <Descriptions.Item span={2} label="채권자명"> <Input value={preCreditorName} onChange={(e)=>setPreCreditorName(e.target.value)}/> </Descriptions.Item>
                                                        <Descriptions.Item span={2} label="전화번호"> <Input value={preCreditorPhone} onChange={(e)=>setPreCreditorPhone(e.target.value)} placeholder={'휴대폰번호 입력(필수)'}/> </Descriptions.Item>
                                                    </Descriptions>

                                                    <div style={{textAlign:'left', fontSize:16, fontWeight:500, margin:8}}>채무자 정보 입력 (아는 부분만)</div>
                                                    <Descriptions size="small" bordered column={2}
                                                                  labelStyle={{width: 100, textAlign: "center"}}
                                                                  style={{textAlign: "left", marginBottom: 24}}>
                                                        <Descriptions.Item  span={2} label="채무자명"> <Input value={preDebtorName} onChange={(e) => setPreDebtorName(e.target.value)} placeholder={'(필수)'}/> </Descriptions.Item>
                                                        <Descriptions.Item  span={2} label="전화번호"> <Input value={preDebtorPhone} onChange={(e)=>setPreDebtorPhone(e.target.value)}/> </Descriptions.Item>
                                                        <Descriptions.Item  span={2} label="주소지"> <Input value={preDebtorAddr} onChange={(e)=>setPreDebtorAddr(e.target.value)}/> </Descriptions.Item>
                                                        <Descriptions.Item  span={2} label="이메일"> <Input value={preDebtorEmail} onChange={(e)=>setPreDebtorEmail(e.target.value)}/> </Descriptions.Item>
                                                    </Descriptions>

                                                    {(finishedStep < 0 && !contentsConfirmed) && (
                                                        <Button color="default" variant="solid" icon={<LikeOutlined />}
                                                                onClick={postPreCreditorDebtorData}
                                                                // onClick={() => {
                                                                //     setContentConfirmed(true);
                                                                //     setCurrentStep(1);
                                                                // }}
                                                        >확인/입력 했습니다</Button>
                                                    )}
                                                    {(finishedStep >= 0 || contentsConfirmed) && (
                                                        <Button color="default" variant="filled" icon={<CheckOutlined />}
                                                                onClick={() => alert("이미 확인하셨습니다")}
                                                        >확인 완료</Button>
                                                    )}
                                                </Space>
                                            )}
                                            {/*{currentStep === 1 && (*/}
                                            {/*    <>*/}
                                            {/*        <Typography.Title level={5}>계약서 내용을 확인하고 동의해주세요</Typography.Title>*/}
                                            {/*        {finishedStep < 1 && (*/}
                                            {/*            <Button color="default" variant="solid" icon={<LikeOutlined />}*/}
                                            {/*                    onClick={agreeToContract}*/}
                                            {/*            >동의합니다</Button>*/}
                                            {/*        )}*/}
                                            {/*        {finishedStep >= 1 && (*/}
                                            {/*            <Button color="default" variant="filled" icon={<CheckOutlined />}*/}
                                            {/*                    onClick={() => alert("이미 동의하셨습니다")}*/}
                                            {/*            >동의 완료</Button>*/}
                                            {/*        )}*/}
                                            {/*    </>*/}
                                            {/*)}*/}
                                            {/*{currentStep === 2 && (*/}
                                            {/*    <>*/}
                                            {/*        <Typography.Title level={5}>개인정보처리방침 내용을 확인하고 동의해주세요</Typography.Title>*/}
                                            {/*        {finishedStep < 2 && (*/}
                                            {/*            <Button color="default" variant="solid" icon={<LikeOutlined />}*/}
                                            {/*                    onClick={agreeToPrivacyPolicy}*/}
                                            {/*            >동의합니다</Button>*/}
                                            {/*        )}*/}
                                            {/*        {finishedStep >= 2 && (*/}
                                            {/*            <Button color="default" variant="filled" icon={<CheckOutlined />}*/}
                                            {/*                    onClick={() => alert("이미 동의하셨습니다")}*/}
                                            {/*            >동의 완료</Button>*/}
                                            {/*        )}*/}
                                            {/*    </>*/}
                                            {/*)}*/}
                                            {currentStep === PAYMENT_STEP && ( //2412 gary: 3->1로 바꿈.
                                                <>
                                                    {/*<Typography.Title level={5}>착수금을 결제해주세요</Typography.Title>*/}

                                                    {finishedStep < PAYMENT_STEP && (
                                                        <>
                                                            <PaymentAgreeModule preBond={preBond} setAllAgreed={setAllAgreed}/>

                                                            <FeePayment preBond={preBond} allAgreed={allAgreed}  setRefreshTime={setRefreshTime}/>
                                                        </>
                                                    )}
                                                    {finishedStep >= PAYMENT_STEP && (
                                                        <>
                                                            <PaymentAgreeModule preBond={preBond}/>
                                                            <p>착수금 결제 이미 완료됨</p>
                                                        </>
                                                    )}
                                                </>
                                            )}

                                            {currentStep >= PAYMENT_STEP+1 && //4 &&
                                                <>
                                                    <CreditorDebtorInfo preBondId={preBond.id}/>
                                                </>
                                            }
                                        </Space>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}

            {isMobile && getMobileOS() === 'iOS' && isKakaoTalkWebView() && (
                <div style={{marginTop:-150, minHeight: 100}}>
                    먼저, Safari로 열기를 선택해주세요.

                    <br/>
                    <FlexColumn style={{marginTop:20, marginBottom:100, alignItems:'center'}}>
                        <div> 1. <b>폰의 우측 하단</b>에 있는 [<UploadOutlined />] 아이콘을 선택 후 </div>
                        <div> 2. <b>아래쪽에 뜨는</b> [Safari로 열기] 를 클릭한 후, <br/>Safari에서 로그인해 주시면 됩니다. </div>
                    </FlexColumn>
                </div>
            )}

            <Footer/>
            <Spin fullscreen size="large" spinning={spinning}/>
        </div>
    );
};

export default PreBondPage;