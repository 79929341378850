import { useRecoilValue } from "recoil";
import { GrayLine, OrangeButton, WhiteTopBar } from "../common/Common";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_URL, BoUserIdAtom, UserObjectIdAtom } from "../Atoms";
import { BoNetUtil, isDevEnv } from "../common/Util";
import commaNumber from "comma-number";
import { Checkbox } from 'primereact/checkbox';
import { Modal } from 'react-responsive-modal';
import {isMobile} from 'react-device-detect';
import {Dropdown} from "primereact";
import {Space, Spin} from "antd";
import {Flex, FlexCenter, FlexColumn} from "../common/DeskComponent";
import useAxios from "../hooks/useAxios";
import { useLocation } from 'react-router-dom';

const PaymentAgreeModule = ({preBond, setAllAgreed}) => {


    // props.caseInfo
    const [all, setAll] = useState(false);
    const [term, setTerm] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const [push, setPush] = useState(false);
    const [age, setAge] = useState(false);

    //1. 위임계약서           1.5 const [payable, setPayable] = useState(false);  // 결제 가능한가?
    const [showMandate, setShowMandate] = useState(false);  // 위임계약서를 보여줄 것인가?
    const [mandateContent, setMandateContent] = useState(null);  // 위임계약서 내용
    const [mandate, setMandate] = useState(false);  // 위임계약
    const [sawMandate, setSawMandate] = useState(false);  // 위임계약 봤나?

    //2. 위임계약서
    const [showMandate2, setShowMandate2] = useState(false);  // 변호사 수임료 지원약정 보여줄 것인가?
    const [mandateContent2, setMandateContent2] = useState(null);  // 변호사 수임료 지원약정 내용
    const [mandate2, setMandate2] = useState(false);  //변호사 수임료 지원약정
    const [sawMandate2, setSawMandate2] = useState(false);  //변호사 수임료 지원약정 봤나?

    const [pay, setPay] = useState(false);  // 결제 진행 동의?


    //1.5 const [payInfo, setPayInfo] = useState(null);  // /vws/bo/payment/req  의 응답. 결제에 필요한 MID, OID 등 가져옴.


    //const boUserId = useRecoilValue(BoUserIdAtom);
    const navigate = useNavigate();


    const setLast = () => {


    }

    //iframe버전: 개발용으로만 사용됬었음.
    const B2cContractIpo = ({preBond, isLawyerFeeDoc=false}) => {

        const [pdfUrl, setPdfUrl] = useState();
        const {axiosGet} = useAxios();
        useEffect(()=> {
            firstSearch();
        },[]);
        const firstSearch = async() => {
            // let res = await axiosGet(`/vws/bo/payment/contractIpo/${caseNo}`) OLD1.5 -> 2.0에서 prebond이용으로 변경함.
            let url = `/api/v1/prebonds/${preBond.id}/contractIpo`;
            if ( isLawyerFeeDoc ) {
                url = `/api/v1/prebonds/${preBond.id}/lawyerFeeIpo`; // laywerFee문서도 필요.변호사수임료지원약정 전용임.
            }

            let res = await axiosGet(url)
            console.log('##ContractIPO: isLawyerFeeDoc, res=',isLawyerFeeDoc, res);
            if (res && res.code > 0) {
                setPdfUrl(res.msg);
            }else {
                console.log('##ContractIPO 오류: res=',res);
            }
        }
        if(!pdfUrl) {
            return <FlexCenter>
                <Spin/>
            </FlexCenter>

        }
        return (
            <iframe src={pdfUrl} style={{width:'100%', height:'370px'}}>
            </iframe>
        )
    }

    const selectAll = (e) => {
        setAll(e.checked);
        setTerm(e.checked);
        setPrivacy(e.checked);
        setPush(e.checked);
        setAge(e.checked);
        if (e.checked === true) {
            if (sawMandate === true) setMandate(e.checked);
        } else {
            setMandate(e.checked);
        }
        setPay(e.checked);
    }

    //TODO caseInfo 제거. 가채권으로 대체
    useEffect(() => {
        console.log('PaymentAgreeModule.js1: preBond.id:', preBond?.id);
        if (!preBond) return;

        console.log('PaymentAgreeModule.js2: preBond.id:', preBond?.id);
        // vws/bo/docs/case/144/contract
        BoNetUtil.get(`${BACKEND_URL}/api/v1/prebonds/${preBond.id}/contract`, null,
            (resp) => {
                console.log('contract1.5', resp.data)
                setMandateContent(resp.data); //1.5 위임계약서 내용을 CaseNo로 받아오는데 -> 2.0에서 preBond로 되는지 검토필요.
            },
            (err) => console.error(err)
        );
    }, [preBond]);


    //TODO boUserId 제거.  가채권으로 계약서동의로 대체.
    const onAgreeMandate = () => {
        // BoNetUtil.put(`${BACKEND_URL}/vws/bo/user/terms/${boUserId}`, {contract: true}, // 동의여부 없어서?
        BoNetUtil.post(`${BACKEND_URL}/api/v1/prebonds/${preBond.id}/contract/agreed`,null,
            (resp) => {
                console.log("mandate = %o", resp.data);
                if (mandate2) {
                    setAllAgreed(true)
                }

                setShowMandate(false); //팝업닫기.
                setMandate(true);
                setSawMandate(true);

            },
            (err) => {
                console.error(err)
            }
        );
    }
    const onAgreeMandate2 = () => {
        // BoNetUtil.put(`${BACKEND_URL}/vws/bo/user/terms/${boUserId}`, {contract: true}, // 동의여부 없어서?
        BoNetUtil.post(`${BACKEND_URL}/api/v1/prebonds/${preBond.id}/lawyerFee/agreed`,null,
            (resp) => {
                console.log("mandate = %o", resp.data);
                setShowMandate2(false); //팝업닫기.
                setMandate2(true);
                setSawMandate2(true);
                if (mandate) {
                    setAllAgreed(true)
                }
            },
            (err) => {
                console.error(err)
            }
        );
    }

    const handleClick1 = () => {
        if (!sawMandate) {
            alert('먼저 필수 내용을 확인해야 합니다.');
        }
    };
    const handleClick2 = () => {
        if (!sawMandate2) {
            alert('먼저 필수 내용을 확인해야 합니다.');
        }
    };

    return (
        <div className="px-3 text-left">
            <div className="my-3">
                <Checkbox checked={all} onChange={selectAll}/>
                <label className="px-2 font-bold">전체동의</label>
            </div>
            <hr/>
            <div className="flex align-items-center my-3">
                <Checkbox checked={term} onChange={(e) => setTerm(e.checked)}/>
                <label className="px-2 text-700">[필수] 이용약관에 동의</label>
                <i className="pi pi-angle-right ml-auto px-2" onClick={(e) => navigate("/terms")}/>
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={privacy} onChange={(e) => setPrivacy(e.checked)}/>
                <label className="px-2 text-700">[필수] 개인정보 수집 및 이용에 동의</label>
                <i className="pi pi-angle-right ml-auto px-2" onClick={(e) => navigate("/privacy")}/>
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={push} onChange={(e) => setPush(e.checked)}/>
                <label className="px-2 text-700">[선택] 정보 안내 수신에 동의</label>
                {/*<i className="pi pi-angle-right ml-auto px-2"/>  /!* TODO: 이건 어디로 링크?? *!/*/}
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={age} onChange={(e) => setAge(e.checked)}/>
                <label className="px-2 text-700">[필수] 본인은 만 14세 이상입니다.</label>
                {/*<i className="pi pi-angle-right ml-auto px-2"/>*/}
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={pay} onChange={(e) => setPay(e.checked)}/>
                <label className="px-2 text-700">[필수] 구매조건 확인 및 결제진행 동의</label>
                {/*<i className="pi pi-angle-right ml-auto px-2" onClick={(e) => navigate("/privacy")}/>*/}
            </div>
            <div className="flex align-items-center my-3">
                <span onClick={handleClick1}>
                    {/*<Checkbox checked={mandate} onChange={(e) => setMandate(e.checked)} readOnly={!sawMandate}/>*/}
                    <Checkbox checked={mandate} readOnly={!sawMandate}/>
                </span>
                <label className="px-2 text-700"
                       onClick={(e) => setShowMandate(true)}>[필수] 위임 계약에 대한 동의</label>
                <i className="pi pi-angle-right ml-auto px-2" onClick={(e) => setShowMandate(true)}/>
            </div>
            <div className="flex align-items-center my-3">
                <span onClick={handleClick2}>
                    {/*<Checkbox checked={mandate2}  onChange={(e) => setMandate2(e.checked)} readOnly={!sawMandate2}/>*/}
                    <Checkbox checked={mandate2} readOnly={!sawMandate2}/>
                </span>
                <label className="px-2 text-700" onClick={(e) => setShowMandate2(true)}>[필수] 변호사수임료 지원에 관한 약정</label>
                <i className="pi pi-angle-right ml-auto px-2" onClick={(e) => setShowMandate2(true)}/>
            </div>
            <br/>


            <Modal open={showMandate} center showCloseIcon={true} onClose={() => setShowMandate(false)}
                   classNames={{modal: "border-round-lg pb-0"}} blockScroll={false}
                   styles={{
                       modal: {
                           width: "85vw",
                           height: "32rem",
                           overflowY: "scroll",
                           overflowX: "hidden",
                           overflowScrolling: "touch",
                           WebkitOverflowScrolling: "touch"
                       }
                   }}>  {/* iphone x 에서 스크롤 안되엇 */}
                <div className="text-center font-bold">위임계약에 대한 동의</div>
                <br/>
                {//isDevEnv() ?
                    <B2cContractIpo preBond={preBond}/>
                    // : //2412이전 상용코드: "contract"를 string으로 리턴하고, 이걸로 thymeleaf페이지 출력하던 이전코드임.
                    // <div dangerouslySetInnerHTML={{__html: mandateContent}}>
                    // </div>
                }

                <br/>
                <div className="">
                    <OrangeButton label="동의" onClick={onAgreeMandate}/>
                </div>
                <br/>
            </Modal>

            <Modal open={showMandate2} center showCloseIcon={true} onClose={() => setShowMandate2(false)}
                   classNames={{modal: "border-round-lg pb-0"}} blockScroll={false}
                   styles={{
                       modal: {
                           width: "85vw",
                           height: "32rem",
                           overflowY: "scroll",
                           overflowX: "hidden",
                           overflowScrolling: "touch",
                           WebkitOverflowScrolling: "touch"
                       }
                   }}>  {/* iphone x 에서 스크롤 안되엇 */}
                <div className="text-center font-bold">변호사수임료 지원에 관한 약정</div>
                <br/>
                {//isDevEnv() ?
                    <B2cContractIpo preBond={preBond} isLawyerFeeDoc={true}/>
                    // : //2412이전 상용코드: "contract"를 string으로 리턴하고, 이걸로 thymeleaf페이지 출력하던 이전코드임.
                    // <div dangerouslySetInnerHTML={{__html: mandateContent}}>
                    // </div>
                }

                <br/>
                <div className="">
                    <OrangeButton label="동의" onClick={onAgreeMandate2}/>
                </div>
                <br/>
            </Modal>

        </div>
    )
}


export default PaymentAgreeModule;