import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OrangeButton, WhiteTopBar} from "../common/Common";
import {FlexColumnCenter} from "../common/DeskComponent";
import {Button, Card, Flex, message, Modal, Select, Space, Spin, Typography} from "antd";
import useAxios from "../hooks/useAxios";
import {BACKEND_URL} from "../Atoms";
import axios from "axios";
import {EmptyBox, FlexColumn} from "../common/B2bComponent";
import {isMobile} from 'react-device-detect';
import {addComma, BoNetUtil, isDevEnv} from "../common/Util";
import PublicFileUploader from "../components/PublicFileUploader";


const payOptions= [//[{ value: 'text', label: 'text' },]
        { value: 99000, label: '9만9천원(vat포함)' },
        { value: 110000, label: '11만원(vat포함)' },
        { value: 220000, label: '22만원(vat포함)' },
        { value: 330000, label: '33만원(vat포함)' },
        { value: 550000, label: '55만원(vat포함)' },
        { value: 660000, label: '66만원(vat포함)' },
        { value: 770000, label: '77만원(vat포함)' },
        { value: 1100000, label: '110만원(vat포함)' },

];

const FeePayment = ({preBond, allAgreed, setRefreshTime}) => {
    console.log('FeePayment: preBond:', preBond, allAgreed);

    const {axiosGet, axiosPost} = useAxios();
    const navigate = useNavigate();

    //잘안됨 const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState(false);

    const [feeAmount, setFeeAmount] = useState(preBond?preBond.feeAmount:undefined);
    const mobilePayForm = useRef(null);

    const [payInfo, setPayInfo] = useState();
    const SERVER_ADDR = BACKEND_URL;//(window.host === 'vws.ai')? 'https://vws.ai': 'https://creditncity-c.com';

    const [showTransferPopup, setShowTransferPopup] = useState();

    const [transferImageUrl, setTransferImageUrl] = useState();

    //개발test용 금액 down용도.
    const [divider, setDivider] = useState(isDevEnv()?1000 : 1);

    const [lawyerFeeAndSupport, setLawyerFeeAndSupport] = useState();


    useEffect(() => {
        firstSearch();
    }, []);

    const firstSearch = async () => {

        if (isMobile) {

            //여기서 호출하면 Payment테이블에 너무 많이 쌓임.
            // let payInfo = await axiosGet('/vws/bo/payment/req/0/mobile');
            // console.log( 'payInfo, divider:', payInfo,  divider);
            //
            // setPayInfo(payInfo);

            //2411추가.
            if(preBond) { //대여금용 결제.
                onChangeFeeAmount(preBond.feeAmount);
            }
        }

        if(preBond) {
            let ret = await axiosGet(`/apiOpen/b2c/lawyerFeeAndSupport/${preBond.bondAmount}/${preBond.deposit}`);
            if (ret) {
                setLawyerFeeAndSupport(ret);
            }
        }
    }


    const onChangeFeeAmount = async (value) => {
        if (!isMobile) {
            alert('pc결제 점검 중입니다. 휴대폰으로 결제해 주세요.');
            return;
        }

        // caseNo = 0으로 하드코딩 중 => 동일한게 발생할 수 있으므로, 결제완료시 max regdate로 검색해야 함.
                                     // PaymentController의  /req/caseNo/mobile 임.
        let payInfo = await axiosGet('/vws/bo/payment/req/0/mobile?userDefinedPayment='+ value);
        console.log( 'payInfo, divider:', payInfo,  divider);


        setFeeAmount(value);
        setPayInfo(payInfo);
    }

    //feeDepositCheckedAt 날짜 기록하고, 원장 생성.
    const doPayDateAndCreateCm = async() => {
        setIsLoading(true);

        let res = await axiosPost(`/api/v1/prebonds/createCmByPayPass/${preBond.id}`, null)
        if (!res || res.code < 0) {
            alert('확인에 오류발생:' + res?.msg);
            return;
        }
        //잘안됨 messageApi.info('결제확인 완료 중- 잠시 기다려주세요');

        //PreBondPage: refresh
        setRefreshTime(new Date())
        //alert('확인되었습니다.');
        setIsLoading(false);

    }

    const doTransferDone = async() => {
        setIsLoading(true);

        let res = await axiosPost(`/api/v1/prebonds/createCmByTransferImage/${preBond.id}`, {value:transferImageUrl})
        if (!res || res.code < 0) {
            alert('완료기록 중 오류발생:' + res?.msg);
            return;
        }

        //PreBondPage: refresh
        setRefreshTime(new Date())
        setIsLoading(false);
    }

    const doPay = async(e) => {
        console.log(e);

        e.preventDefault(); // 기본 폼 제출 동작 방지

        if (!isMobile) {
            alert('pc결제 점검 중입니다. 휴대폰으로 결제해 주세요.');
            return;
        }
        if (!feeAmount) {
            alert('결제금액을 먼저 선택해 주세요.');
            return;
        }

        if (!payInfo) {
            alert(' 결제관련 정보수신 중. 잠시후 다시클릭해 주세요');
            return;
        }

        if (preBond) {
            let res = await axiosPost(`/api/v1/prebonds/tempOid/${preBond.id}/${payInfo.oid}`, null)
            // let {data:res} =  await axios.post(`${BACKEND_URL}/api/v1/prebonds/tempOid/${preBond.id}/${payInfo.oid}`, null)
            if (!res || res.code < 0) {
                alert('결제정보 저장오류:' + res?.msg);
                return;
            }
        }

        if (isMobile) {
            mobilePayForm.current.action = "https://mobile.inicis.com/smart/payment/";
            mobilePayForm.current.target = "_self";
            mobilePayForm.current.submit();
        }
        //
        // try {
        //     const formData = new FormData(mobilePayForm.current);
        //     const response = await fetch("https://mobile.inicis.com/smart/payment/", {
        //         method: "POST",
        //         body: formData,
        //     });
        //
        //     if (response.ok) {
        //         const result = await response.json(); // JSON 형태의 응답일 경우
        //         console.log('결과:', result);
        //
        //     } else {
        //         console.error('오류 발생:', response.status);
        //     }
        // } catch (error) {
        //     console.error('요청 실패:', error);
        // }
    }



    return(
        <>
            <div className="">

                {!preBond &&
                    <div className="sticky top-0 z-5">
                        <WhiteTopBar title={'수임료 결제'}/>
                    </div>
                }

                <div style={{textAlign: 'left', marginTop: 16, marginLeft: 16, fontSize: 16, fontWeight: 700}}> 한시적 혜택가
                    {!preBond &&
                        <span style={{fontSize: 16, fontWeight: 700, color: 'orangered'}}> (90% 할인)</span>
                    }

                    {preBond && Array.isArray(lawyerFeeAndSupport) &&
                        <span style={{fontSize: 16, fontWeight: 300}}> - 수임료:{lawyerFeeAndSupport[0]},
                            <span style={{fontSize: 16, fontWeight: 700, color: 'orangered'}}> 지원금:{lawyerFeeAndSupport[1]}</span>
                        </span>
                    }
                </div>

                <FlexColumnCenter>
                    {/*<Card title={<div style={{ fontSize: 18}}>착수금을 결제해주세요</div>} hoverable="true"*/}
                    {/*      style={{width: "80%"}}>*/}
                    {/*    <div className="px-3" >*/}

                    {/*        <div style={{fontSize: 18, fontWeight: 700}}> 결제하실 금액을 {preBond?'확인':'선택'}하신 후</div>*/}
                    {/*        <div style={{fontSize: 18}}> 결제진행 버튼을 눌러주세요.</div>*/}

                    {/*    </div>*/}
                    {/*</Card>*/}

                    <Flex style={{marginTop: 32}}><Space>
                        <div style={{fontSize: 16, fontWeight: 400}}> 착수금{preBond ? '' : '선택'} </div>
                        {/*<div style={{fontSize: 16, fontWeight: 700, color:'orangered'}}> 90% </div>*/}
                        <Select
                            placeholder={'선택해주세요'}
                            style={{width: 160, fontSize: 16}}
                            value={feeAmount}
                            onChange={value => onChangeFeeAmount(value)}
                            options={ preBond? [{label:`${addComma(feeAmount)}(vat포함)`, value:feeAmount} ]
                                               :
                                               payOptions}
                        />
                        {feeAmount && !preBond &&
                            <div style={{fontSize: 12, fontWeight: 700, color:'gray', textDecoration: "line-through"}}> {addComma(feeAmount*10)}원 </div>
                        }
                    </Space></Flex>


                    {(preBond && preBond.latePreBondAlreadyPaid)?
                        <EmptyBox>
                            <>
                                <Button disabled={!allAgreed} style={{fontSize: 16, width: 300, height: 40, backgroundColor:(!allAgreed?'lightgray':'#ffd118'), color: 'black'}}
                                        type={'primary'} onClick={doPayDateAndCreateCm}> 이미 결제완료했습니다.(꼭 클릭 필요) </Button>
                                {isLoading &&
                                    <Spin/>
                                }
                            </>
                        </EmptyBox>
                        :
                        <>
                            <EmptyBox>
                                <Button disabled={preBond && !allAgreed}
                                    style={{fontSize: 16, width: 250, height: 40, backgroundColor:( (preBond && !allAgreed)?'lightgray':'#ffd118'), color: 'black'}}
                                    type={'primary'} onClick={doPay}> 카드결제진행 </Button>

                                {preBond &&
                                    <>
                                        <br/>
                                        <Button disabled={preBond && !allAgreed}
                                                style={{marginTop:16, fontSize: 16, width: 250, height: 40, backgroundColor:( (preBond && !allAgreed)?'lightgray':'#ffd118'), color: 'black'}}
                                                type={'primary'} onClick={() => setShowTransferPopup(true)}> 계좌이체 + 사진첨부 </Button>
                                    </>
                                }
                            </EmptyBox>


                            {/*<div style={{ fontSize: 14}}> (계좌이체는 카톡으로 문의해 주세요.)</div>*/}
                        </>
                    }


                </FlexColumnCenter>

                <div className={'hidden'}>
                    {/* 결제 Form*/}
                    <form ref={mobilePayForm} method="POST" acceptCharset="EUC-KR">
                        <input type="hidden" name="P_INI_PAYMENT" value={'CARD'}/>

                        <input type="hidden" name="P_MID" value={payInfo?.mid}/>
                        <input type="hidden" name="P_OID" value={payInfo?.oid}/>
                        <input type="hidden" name="P_AMT" value={feeAmount / divider}/>
                        <input type="hidden" name="P_GOODS" value={"채권도시 수임료"}/>
                        <input type="hidden" name="P_UNAME" value={payInfo?.uname}/>
                        <input type="hidden" name="P_MOBILE" value={payInfo?.mobile}/>
                        <input type="hidden" name="P_EMAIL" value={payInfo?.email}/>
                        <input type="hidden" name="P_NEXT_URL" value={SERVER_ADDR + "/cncc/cb/inicis/mobile"}/>
                        <input type="hidden" name="P_NOTI_URL" value={SERVER_ADDR + "/cncc/cb/inicis/vbankmobile"}/>
                        <input type="hidden" name="P_CHARSET" value="utf8"/>
                        <input type="hidden" name="P_RESERVED" value="below1000=Y&vbank_receipt=Y&centerCd=Y"/>
                        <input type="hidden" name="P_NOTI" value={payInfo?.oid}/> {/* 결제 실패시, OID를 내려줘서 여기에 담음 */}

                        {/*<Button style={{marginTop:16, fontSize:16, width:250, height:40, backgroundColor:'#ffd118', color:'black'}} type={'submit'} > 결제 진행 </Button>*/}
                    </form>
                </div>

                {!preBond &&
                    <>
                        <br/><br/><br/>
                        <img style={{width: '70%'}} src={'/assets/logoC.png'}/>
                    </>
                }

            </div>
            <Modal open={showTransferPopup} title={"[계좌이체 후, 내역캡쳐해서 올리기]"}
                   onCancel={() => setShowTransferPopup(false)}
                   footer={null}
                   destroyOnClose={true}
                   getContainer={false}
                   bodyStyle={{padding: 0}}
                   width={900}
                   maskClosable={false}
            >
                <FlexColumn style={{marginLeft: 32}}>
                    <div style={{marginTop: 32, fontSize: 16, fontWeight: 700}}>1. 입금계좌: 신한은행
                        <Typography.Text style={{fontSize: 18}} code copyable>110-558-928125</Typography.Text>
                    </div>
                    <div style={{marginTop: 12, marginLeft: 32, fontSize: 16}}> (예금주: 김주현(채권도시 법률사무소) )</div>


                    <div style={{marginTop: 32, fontSize: 16, fontWeight: 700}}>2. 이체증/이체내역 이미지 첨부하기</div>
                    <div style={{marginTop: 12, marginLeft: 32}}>
                        <PublicFileUploader imageUrl={transferImageUrl} acceptImage={true}
                                            downloadName={'계좌이체내역'} //downloadOnly={replyExist}
                                            onChange={(returnUrl) => setTransferImageUrl(returnUrl) }/>
                    </div>


                </FlexColumn>


                <br/>
                <Button disabled={!transferImageUrl} type={'primary'}
                        style={{marginTop: 16, fontSize: 16, width: 250, height: 40, backgroundColor: transferImageUrl?'#ffd118':'lightgray', color: 'black'}}
                        onClick={() => doTransferDone()}> 이체완료 </Button>
                {isLoading &&
                    <Spin/>
                }

            </Modal>
        </>
    )
}

export default FeePayment;